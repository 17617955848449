<script setup lang="ts">
import type { ButtonColor, ButtonSize, ButtonType, ButtonVariant } from '@/types/button';

const props = defineProps({
	variant: {
		type: String as PropType<ButtonVariant>,
		default: 'solid',
	},
	color: {
		type: String as PropType<ButtonColor>,
		default: 'primary',
	},
	size: {
		type: String as PropType<ButtonSize>,
		default: 'lg',
	},
	type: {
		type: String as PropType<ButtonType>,
		default: 'button',
	},
	iconOnly: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

defineEmits<{(e: 'click'): void; }>();

const btnRef = ref();

const colorClass = computed(() => {
	if (!BtnVariant.includes(props.variant)) {
		throw new TypeError(`Invalid variant prop: '${props.variant}'. Allowed values are ${BtnVariant.join(', ')}.`);
	}

	if (!BtnColor.includes(props.color)) {
		throw new TypeError(`Invalid color prop: '${props.color}'. Allowed values are ${BtnColor.join(', ')}.`);
	}

	return `${props.variant} --color-${props.color}`;
});

const sizeClass = computed(() => {
	if (!BtnSize.includes(props.size)) {
		throw new TypeError(`Invalid size prop: '${props.size}'. Allowed values are ${BtnSize.join(', ')}`);
	}

	return `size-${props.size}`;
});
</script>

<template>
  <button
    ref="btnRef"
    :disabled="disabled"
    :class="['btn', colorClass, sizeClass, { 'cursor-pointer': !disabled, 'btn-icon': iconOnly }]"
    :type="type"
    @click="$emit('click')"
  >
    <slot name="leftIcon" />
    <slot />
    <slot name="rightIcon" />
    <VTooltip
      v-if="$slots['tooltip']"
      :activator="btnRef"
      location="bottom left"
    >
      <div class="tooltip">
        <slot name="tooltip" />
      </div>
    </VTooltip>
  </button>
</template>

<style lang="scss" scoped>
.btn {
	@include border-radius-default;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: spacings-get(2);
	border-style: unset;
	outline: none;
	font-family: inherit;
	height: fit-content;

	&:disabled {
		cursor: not-allowed;
	}
}

.solid {
	&.--color-primary {
		color: colors-get(base, white);
		border: 1px solid colors-get(primary, 600);
		background-color: colors-get(primary, 600);
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

		&:hover {
			border: 1px solid colors-get(primary, 700);
			background-color: colors-get(primary, 700);
		}

		&:focus {
			border: 1px solid colors-get(primary, 700);
			background-color: colors-get(primary, 700);
			box-shadow: 0px 0px 0px 4px #F4EBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}

		&:disabled {
			border: 1px solid colors-get(primary, 200);
			background-color: colors-get(primary, 200);
		}
	}

	&.--color-error {
		color: colors-get(base, white);
		border: 1px solid colors-get(error, 600);
		background-color: colors-get(error, 600);
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

		&:hover {
			border: 1px solid colors-get(error, 700);
			background-color: colors-get(error, 700);
		}

		&:focus {
			border: 1px solid colors-get(error, 600);
			background-color: colors-get(error, 600);
			box-shadow: 0px 0px 0px 4px #{colors-get(error, 200)}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}

		&:disabled {
			border: 1px solid colors-get(error, 200);
			background-color: colors-get(error, 200);
		}
	}
}

.outlined {
	&.--color-primary {
		color: colors-get(primary, 700);
		border: 1px solid colors-get(primary, 200);
		background-color: colors-get(primary, 50);
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

		&:hover {
			color: colors-get(primary, 800);

			&:not(:disabled) {
				background-color: colors-get(primary, 100);
			}
		}

		&:focus {
			box-shadow: 0px 0px 0px 4px #F4EBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}

		&:disabled {
			color: colors-get(primary, 300);
			border: 1px solid colors-get(primary, 25);
			background-color: colors-get(primary, 25);
		}
	}

	&.--color-gray {
		color: colors-get(gray, 700);
		border: 1px solid colors-get(gray, 300);
		background-color: colors-get(base, white);
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

		&:hover {
			color: colors-get(gray, 800);

			&:not(:disabled) {
				background-color: colors-get(gray, 50);
			}
		}

		&:focus {
			box-shadow: 0px 0px 0px 4px #{colors-get(gray, 100)}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}

		&:disabled {
			color: colors-get(gray, 300);
			border: 1px solid colors-get(gray, 200);
		}
	}

	&.--color-tertiary-gray {
		color: colors-get(base, white);
		border: 1px solid colors-get(base, white);
		background-color: transparent;

		&:hover:not(:focus) {
			color: colors-get(gray, 300);
			border: 1px solid colors-get(gray, 300);
		}

		&:focus {
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px colors-get(gray, 100);
		}

		&:disabled, &:disabled:hover {
			color: colors-get(gray, 600);
			border: 1px solid colors-get(gray, 600);
		}
	}
}

.subtle {
	&.--color-primary {
		color: colors-get(primary, 700);
		background-color: unset;
		border: 1px solid transparent;

		&:hover {
			color: colors-get(primary, 800);
			background-color: colors-get(primary, 50);
			border: 1px solid colors-get(primary, 50);
		}

		&:disabled {
			color: colors-get(gray, 300);
			background-color: transparent;
			border-color: transparent;
		}
	}

	&.--color-gray {
		color: colors-get(gray, 600);
		background-color: unset;
		border: 1px solid transparent;

		&:hover {
			color: colors-get(gray, 700);
			background-color: colors-get(gray, 50);
			border: 1px solid colors-get(gray, 50);
		}

		&:disabled {
			color: colors-get(gray, 300);
			background-color: transparent;
			border-color: transparent;
		}
	}

	&.--color-tertiary-gray {
		color: colors-get(base, white);
		background-color: transparent;
		border-color: transparent;

		&:hover {
			color: colors-get(gray, 300);
		}

		&:disabled {
			color: colors-get(gray, 700);
		}
	}
}

.link {
	&.--color-primary {
		color: colors-get(primary, 700);
		background-color: unset;

		&:hover {
			color: colors-get(primary, 800);
		}

		&:disabled {
			color: colors-get(gray, 300);
		}
	}

	&.--color-gray {
		color: colors-get(gray, 600);
		background-color: unset;

		&:hover {
			color: colors-get(gray, 700);
		}

		&:disabled {
			color: colors-get(gray, 300);
		}
	}
}

.size-sm {
	@include fonts-get(semibold, 'text-sm');
	padding: spacings-get(2) rem(14);

	&.btn-icon {
		padding: spacings-get(2);
	}
}

.size-md {
	@include fonts-get(semibold, 'text-sm');
	padding: rem(10) spacings-get(4);

	&.btn-icon {
		padding: rem(10);
	}
}

.size-lg {
	@include fonts-get(semibold, 'text-md');
	padding: rem(10) rem(18);

	&.btn-icon {
		padding: spacings-get(3);
	}
}

.size-xl {
	@include fonts-get(semibold, 'text-md');
	padding: spacings-get(3) spacings-get(5);

	&.btn-icon {
		padding: rem(14);
	}
}

.size-2xl {
	@include fonts-get(semibold, 'text-lg');
	padding: spacings-get(4) rem(28);

	&.btn-icon {
		padding: spacings-get(4);
	}
}
</style>
