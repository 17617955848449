<script lang="ts" setup>
import type { ExtendedLocationDetail } from '@/types/location';

const props = defineProps({
	locationInput: {
		type: String,
		default: '',
		required: true,
	},
	keywordInput: {
		type: String,
		default: '',
		required: true,
	},
	isShowLocationMenu: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	isTransparentBackground: {
		type: Boolean,
		default: false,
		required: true,
	},
	options: {
		type: Object as PropType<google.maps.places.AutocompleteOptions | null>,
		default: null,
	},
});

const emit = defineEmits<{
	(e: 'onSelectLocation', location: ExtendedLocationDetail): void
	(e: 'onLocationInputChange', value: string): void
	(e: 'onKeywordInputChange', value: string): void
	(e: 'onFocusLocation'): void
	(e: 'onFocusKeyword'): void
	(e: 'onSelectCurrentLocation'): void
	(e: 'onSelectEverywhere'): void
	(e: 'blur'): void
	(e: 'enter'): void
}>();

const isOpen = ref(false);

const location = computed({
	get: () => props.locationInput || '',
	set: (value) => emit('onLocationInputChange', value),
});

const keyword = computed({
	get: () => props.keywordInput || '',
	set: (value) => emit('onKeywordInputChange', value),
});

function handleClose() {
	isOpen.value = false;
}

function handlePressEnter() {
	isOpen.value = false;

	emit('enter');
}

function handleOnSelectLocation(selectedLocation: ExtendedLocationDetail) {
	isOpen.value = false;
	emit('onSelectLocation', selectedLocation);
}

function handleSelectCurrentLocation() {
	isOpen.value = false;
	emit('onSelectCurrentLocation');
}

function handleSelectEverywhere() {
	isOpen.value = false;
	emit('onSelectEverywhere');
}
</script>

<template>
  <div class="search-nav">
    <BaseButton
      class="search-button"
      variant="link"
      @click="isOpen = !isOpen"
    >
      <SearchIcon :color="isTransparentBackground ? ICON_COLOR.WHITE : '#667085'" />
    </BaseButton>
    <MenuSideDrawer
      class="menu-drawer"
      :is-open="isOpen"
      @on-close="handleClose"
    >
      <div class="menu-container-content">
        <label
          class="menu-label"
          for="menu-activator"
        >
          Search events
        </label>

        <BaseTextInput
          v-model="keyword"
          class="keyword-search-input"
          placeholder="Search events"
          :disabled="disabled"
          @focus="emit('onFocusKeyword')"
          @blur="emit('blur')"
          @enter="handlePressEnter"
        >
          <template #leftIcon>
            <SearchIcon
              class="search-icon"
              width="20"
              height="20"
              color="#667085"
            />
          </template>
        </BaseTextInput>

        <div class="location-search-input">
          <BaseGoogleMapsAutoComplete
            id="menu-activator"
            v-model="location"
            :options="options"
            placeholder="Search by city"
            :disabled="disabled"
            is-search-bar
            @on-select-location="handleOnSelectLocation"
            @focus="emit('onFocusLocation')"
            @blur="emit('blur')"
            @enter="emit('enter')"
          >
            <template #icon>
              <MarkerPinIcon
                class="pin-icon"
                width="20"
                height="20"
                color="#667085"
              />
            </template>
          </BaseGoogleMapsAutoComplete>

          <VList
            v-if="isShowLocationMenu"
            class="location-menu"
          >
            <VListItem
              min-height="36"
              class="text-regular"
              :ripple="false"
              @click="handleSelectCurrentLocation"
            >
              <button class="location-menu-item">
                <MarkerPinAltIcon color="#475467" />
                <p>Use my current location</p>
              </button>
            </VListItem>
            <VListItem
              min-height="36"
              class="text-regular"
              :ripple="false"
              @click="handleSelectEverywhere"
            >
              <button class="location-menu-item">
                <GlobePinIcon color="#475467" />
                <p>Everywhere</p>
              </button>
            </VListItem>
          </VList>
        </div>

        <BaseButton @click="handlePressEnter">
          Search
        </BaseButton>
      </div>
    </MenuSideDrawer>
  </div>
</template>

<style scoped lang="scss">
.search-nav {
  display: flex;
  align-items: center;

  .search-button {
    padding: spacings-get(2);
  }

  .menu-drawer {
    :deep(.drawer-content) {
      &.--extend {
        height: inherit;
      }
    }
  }

  .menu-container-content {
    display: flex;
    flex-direction: column;
    gap: spacings-get(4);
    padding: 0 spacings-get(4);

    .pin-icon,
    .search-icon {
      margin-left: spacings-get(3);
      min-width: spacings-get(5);
    }

    .location-search-input {
      position: relative;

      :deep(.text-field-wrapper) {
        position: relative;

        svg {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: 2;
        }

        .text-field {
          padding-left: rem(46);
        }
      }
    }
  }
}
</style>