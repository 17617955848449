import type { LocationCoordinate } from '@/types/location';
import { Loader } from '@googlemaps/js-api-loader';

const apiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;

export function useGoogleMaps() {
	const geocoder = ref<google.maps.Geocoder | null>(null);
	const autocomplete = ref<google.maps.places.Autocomplete | null>(null);

	const loader = new Loader({
		apiKey,
		version: 'weekly',
		libraries: ['places'],
	});

	async function initGeocoder(): Promise<void> {
		const { Geocoder } = await loader.importLibrary('geocoding');
		geocoder.value = new Geocoder();
	}

	async function initAutocomplete(input: HTMLInputElement, options?: google.maps.places.AutocompleteOptions | null): Promise<void> {
		const { Autocomplete } = await loader.importLibrary('places');
		autocomplete.value = new Autocomplete(input, options);
	}

	function buildGeocodeRequest(
		places?: google.maps.places.PlaceResult,
		coordinates?: LocationCoordinate | null,
	) {
		return {
			...(places?.formatted_address ? { address: places.formatted_address } : {}),
			...(coordinates?.lat
				? {
					location: {
						lat: coordinates.lat ?? 0,
						lng: coordinates.lng ?? 0,
					},
				}
				: {}),
			language: 'en',
			region: 'US',
		};
	}

	// Parse geocode results and extract country code, country name, and city name
	function parseGeocodeResults(results: google.maps.GeocoderResult[]) {
		let cityName = '';
		// Use the first index to find the country code of the selected location
		const firstIndex = 0;
		// Select the second to last index for the best city name result
		const secondToLastIndex = results.length - 2;

		// Find the most specific city name first in order to display on the input
		const cityTypes = ['postal_town', 'locality', 'administrative_area_level_1', 'administrative_area_level_2'];
		for (const cityType of cityTypes) {
			const addressComponent = results?.[secondToLastIndex]?.address_components?.find((component) => component.types.includes(cityType));
			if (addressComponent) {
				cityName = addressComponent.long_name || '';
				break;
			}
		}
		const countryName = results?.[secondToLastIndex]?.address_components?.find((component) => component.types.includes('country'))?.long_name ?? '';

		// Find the country code
		const addressComponents = results?.[firstIndex].address_components;
		const countryCode = addressComponents?.find((component) => component.types.includes('country'))?.short_name || '';

		return { countryCode, countryName, cityName };
	}

	async function fetchLocation(
		places?: google.maps.places.PlaceResult,
		coordinates?: LocationCoordinate | null,
	) {
		if (!geocoder.value) {
			return {
				countryCode: '',
				countryName: '',
				cityName: '',
			};
		}

		const request = buildGeocodeRequest(places, coordinates);
		const { results } = await geocoder.value.geocode(request);

		return parseGeocodeResults(results);
	}

	return {
		geocoder,
		autocomplete,
		initGeocoder,
		initAutocomplete,
		fetchLocation,
	};
}