import type { Timezone } from '@/types/country-timezone';
import { sortBy } from 'lodash';
import type { ComputedRef, Ref } from 'vue';

export function useTimezoneList(date: ComputedRef<string> | Ref<string>, countryId: ComputedRef<number | string> | Ref<number | string>) {
	const timezones = ref<Timezone[]>([]);
	const countryStore = useCountryStore();

	function getTimezoneList(dateTime = '', currentCountryId: string | number = DEFAULT_COUNTRY) {
		const matchedCountry = countryStore.countries.find((country) => country.id === currentCountryId);
		if (matchedCountry) {
			const result = transformTimezones(matchedCountry.timezones, dateTime);
			return sortBy(result, 'name');
		}
		return [];
	}

	onMounted(async () => {
		if (!countryStore.countries.length) {
			await countryStore.getCountries();
		}

		timezones.value = getTimezoneList(date.value || '');
	});

	watch([date, countryId], ([newDate, newCountryId]) => {
		if (newDate && newCountryId) {
			const result = getTimezoneList(newDate, newCountryId);
			timezones.value = result;
		}
	});

	return {
		timezones,
	};
}
