<script setup lang="ts">
import type { CHIP_COLOR_TYPE, CHIP_SIZE_TYPE, CHIP_DENSITY_TYPE } from '@/types/vuetify';

const props = defineProps({
	color: {
		type: String as PropType<CHIP_COLOR_TYPE>,
		default: CHIP_COLOR.PRIMARY,
	},
	size: {
		type: String as PropType<CHIP_SIZE_TYPE>,
		default: CHIP_SIZE.SMALL,
	},
	density: {
		type: String as PropType<CHIP_DENSITY_TYPE>,
		default: CHIP_DENSITY.COMFORTABLE,
	},
	closable: {
		type: Boolean,
		default: false,
	},
});

defineEmits<{ (e: 'onClose'): void; }>();

const colorType = computed(() => {
	switch (props.color) {
		case CHIP_COLOR.PRIMARY:
			return '--primary';
		case CHIP_COLOR.GRAY:
			return '--gray';
		case CHIP_COLOR.SUCCESS:
			return '--success';
		case CHIP_COLOR.ERROR:
			return '--error';
		case CHIP_COLOR.WARNING:
			return '--warning';
		default:
			return '--primary';
	}
});
</script>

<template>
  <VChip
    variant="flat"
    :class="['base-chip', colorType]"
    :size="size"
    :density="density"
  >
    <slot />
    <BaseButton
      v-if="closable"
      class="btn-close"
      variant="link"
      icon-only
      @click="$emit('onClose')"
    >
      <CloseIcon
        width="12"
        height="12"
      />
    </BaseButton>
  </VChip>
</template>

<style scoped lang="scss">
.base-chip {
	width: fit-content;
	height: unset !important;
  min-height: rem(22);
	@include fonts-weight(medium);
  border-width: 1px;
	overflow-wrap: break-word;
	white-space: pre-line;

	&.--primary {
    color: colors-get(primary, 700);
    border-color: colors-get(primary, 200);
    background-color: colors-get(primary, 50);
  }

	&.--warning {
    color: colors-get(warning, 700);
    border-color: colors-get(warning, 200);
    background-color: colors-get(warning, 50);
  }

  &.--success {
    color: colors-get(success, 700);
    border-color: colors-get(success, 200);
    background-color: colors-get(success, 50);
  }

  &.--gray {
    color: colors-get(gray, 700);
    border-color: colors-get(gray, 200);
    background-color: colors-get(gray, 50);
  }

  &.--error {
    color: colors-get(error, 700);
    border-color: colors-get(error, 200);
    background-color: colors-get(error, 50);
  }

  .btn-close {
    padding: 0 0 0 spacings-get(1);
  }

  :deep(.v-chip__content) {
    gap: rem(6);
  }
}
</style>