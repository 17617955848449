import type { List } from '@/types/common';
import type { EventInfo } from '@/types/event';
import type {
	UserProfileResponse,
	UserEventResponse,
	TicketDetailResponse,
	TicketInfo,
	UserGroupRoleResponse,
	TicketOwnershipResponse,
	UserEventAttendingFilterInfo,
	UserEventHostingFilterInfo,
} from '@/types/profile';
import type { CreatedGroupInfoResponse } from '@/types/group';
import type { TicketReceiptResponse } from '@/types/ticket';

interface UserStore {
	userData: {
		data: UserProfileResponse | null
		isLoading: boolean
	},
	userPrimaryGroup: {
		data: CreatedGroupInfoResponse | null
		isLoading: boolean
	},
	ticketData: TicketDetailResponse | null
	userEventAttending: {
		isLoading: boolean
		response: List<UserEventResponse> | null
		errorMessage: string
	}
	userEventHosting: {
		isLoading: boolean
		singleResponse: List<UserEventResponse> | null
		allResponse: List<UserEventResponse> | null
		errorMessage: string
	}
	ownership: {
		data: TicketOwnershipResponse | null
		errorMessage: string,
	},
	userTicketReceipt: {
		data: TicketReceiptResponse | null
		isLoading: boolean
		errorMessage: string
	}
	role: {
		data: UserGroupRoleResponse | null
		isNotMember: boolean
	}
	follower: {
		isUpdated: boolean;
	}
	isLoading: boolean
	errorMessage: string
}

const useProfileStore = defineStore('user', {

	state: (): UserStore => ({
		userData: {
			data: null,
			isLoading: false,
		},
		userPrimaryGroup: {
			data: null,
			isLoading: false,
		},
		ticketData: null,
		userEventAttending: {
			isLoading: false,
			response: null,
			errorMessage: '',
		},
		userEventHosting: {
			isLoading: false,
			singleResponse: null,
			allResponse: null,
			errorMessage: '',
		},
		ownership: {
			data: null,
			errorMessage: '',
		},
		userTicketReceipt: {
			data: null,
			isLoading: false,
			errorMessage: '',
		},
		role: {
			data: null,
			isNotMember: false,
		},
		follower: {
			isUpdated: false,
		},
		isLoading: false,
		errorMessage: '',
	}),

	getters: {
		user: (state: UserStore) => {
			if (!state.userData.data) {
				return null;
			}

			return transformUserProfile(state.userData.data);
		},

		ticketInfo: (state: UserStore): TicketInfo | null => {
			if (!state.ticketData) {
				return null;
			}

			return transformTicket(state.ticketData);
		},

		ticketIds: (state: UserStore) => {
			if (!state.ticketData) {
				return '';
			}

			return state.ticketData.orders.map((order) => order.id).join(',');
		},

		userEventAttendingList: (state: UserStore): EventInfo[] => {
			if (!state.userEventAttending.response) {
				return [];
			}

			return transformUserEventAttendingList(state.userEventAttending.response.data);
		},

		userEventHostingList: (state: UserStore): EventInfo[] => {
			if (!state.userEventHosting.singleResponse) {
				return [];
			}

			return transformUserEventHostingList(state.userEventHosting.singleResponse.data);
		},

		hasTicketOwnership: (state: UserStore) => {
			if (!state.ownership.data) {
				return false;
			}

			return !!state.ownership.data.id;
		},

		userTicketReceiptInfo: (state: UserStore) => {
			if (!state.userTicketReceipt.data) {
				return null;
			}

			return transformTicketReceipt(state.userTicketReceipt.data);
		},

		isOwner: (state: UserStore) => {
			if (!state.role.data) {
				return false;
			}

			return !!(state.role.data.role === GROUP_ROLE.OWNER || state.role.data.role === GROUP_ROLE.ADMIN);
		},

		userRole: (state: UserStore) => {
			if (!state.role.data) {
				return null;
			}

			return state.role.data.role;
		},
	},

	actions: {
		async getUserById(id: number) {
			this.userData.isLoading = true;
			const { data: result, errorMessage } = await getUserInfoByIdAPI(id);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.userData.data = result.value;
			}
			this.userData.isLoading = false;
		},

		async getUserAttendingEventById(id: number, filter: UserEventAttendingFilterInfo) {
			this.userEventAttending.isLoading = true;
			const { data: result, errorMessage } = await getUserAttendingEventByIdAPI(id, filter);

			if (errorMessage.value) {
				this.userEventAttending.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.userEventAttending.response = result.value;
			}
			this.userEventAttending.isLoading = false;
		},

		/**
		 * Fetches user hosting events by a single filter.
		 *
		 * @param id - The user ID.
		 * @param filter - The filter options.
		 *
		 * @remarks
		 * This function fetches user hosting events by a single filter (active, upcoming, or archived).
		 * It stores the result in `userEventHosting.singleResponse`.
		 */
		async getUserHostingEventBySingleFilter(id: number, filter: UserEventHostingFilterInfo) {
			const { data: result, errorMessage } = await getUserHostingEventByIdAPI(id, filter);

			if (errorMessage.value) {
				this.userEventHosting.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.userEventHosting.singleResponse = result.value;
			}
		},

		/**
		 * Fetches user hosting events by all filters.
		 *
		 * @param id - The user ID.
		 * @param filter - The filter options.
		 *
		 * @remarks
		 * This function fetches both archived and upcoming hosting events, but only returns the events that match the selected filter.
		 * If the selected filter is not either archived or upcoming, it will return an error message.
		 *
		 * @returns The merged response of archived and upcoming hosting events.
		 */
		async getUserHostingEventByAllFilter(id: number, filter: UserEventHostingFilterInfo) {
			const selectedFilter = filter.filter;

			// Define the filter payloads
			const filters = [
				USER_EVENT_HOSTING_FILTER.ARCHIVED,
				USER_EVENT_HOSTING_FILTER.UPCOMING,
			];

			// Create promises for both API calls
			const promises = filters.map((item) => getUserHostingEventByIdAPI(id, {
				...filter,
				filter: item,
				itemPerPage: item === selectedFilter ? PAGINATION_CONFIG.ITEM_PER_PAGE : 1, // Fetch only a single event if the filter does not match the selected filter
			}));

			// Wait for both API calls to complete
			const results = await Promise.all(promises);

			// Extract the data and error messages from the results
			const [archivedResult, upcomingResult] = results;

			// Handle error messages
			if (archivedResult.errorMessage.value || upcomingResult.errorMessage.value) {
				switch (selectedFilter) {
					case USER_EVENT_HOSTING_FILTER.ARCHIVED:
						this.userEventHosting.errorMessage = archivedResult.errorMessage.value;
						break;
					case USER_EVENT_HOSTING_FILTER.UPCOMING:
						this.userEventHosting.errorMessage = upcomingResult.errorMessage.value;
						break;
					default:
						this.userEventHosting.errorMessage = COMMON_ERROR_MESSAGE.TRY_AGAIN;
						break;
				}
			}

			if (
				archivedResult.data.value?.data &&
				upcomingResult.data.value?.data &&
				Array.isArray(archivedResult.data.value?.data) &&
				Array.isArray(upcomingResult.data.value?.data)
			) {
				// Ensure data is an array before merging
				const archivedEvents = archivedResult.data.value.data;
				const upcomingEvents = upcomingResult.data.value.data;

				switch (selectedFilter) {
					case USER_EVENT_HOSTING_FILTER.ARCHIVED:
						this.userEventHosting.singleResponse = {
							data: archivedEvents,
							paginationInfo: archivedResult.data.value.paginationInfo,
						};
						break;
					case USER_EVENT_HOSTING_FILTER.UPCOMING:
						this.userEventHosting.singleResponse = {
							data: upcomingEvents,
							paginationInfo: upcomingResult.data.value.paginationInfo,
						};
						break;
					default:
						this.userEventHosting.singleResponse = null;
						break;
				}

				// Merge the response arrays
				const mergedResponse = [...archivedEvents, ...upcomingEvents];

				// Set the merged response
				this.userEventHosting.allResponse = {
					data: mergedResponse,
					paginationInfo: archivedResult.data.value.paginationInfo || upcomingResult.data.value.paginationInfo,
				};
			} else {
				this.userEventHosting.singleResponse = null;
				this.userEventHosting.allResponse = null;
			}
		},

		async getUserHostingEventById(id: number, filter: UserEventHostingFilterInfo, isOrganizerFacing: boolean = false) {
			this.userEventHosting.isLoading = true;

			if (isOrganizerFacing) {
				await this.getUserHostingEventBySingleFilter(id, filter);
			} else {
				await this.getUserHostingEventByAllFilter(id, filter);
			}

			this.userEventHosting.isLoading = false;
		},

		async getTicketDetail(userId: number, eventId: number) {
			this.isLoading = true;
			this.ticketData = null;

			const { data, errorMessage } = await getUserAttendanceByEventId(userId, eventId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.ticketData = data.value;
			}
			this.isLoading = false;
		},

		async follow(
			userId: number,
			groupId: number,
			role: string = FOLLOWING_TYPE.FOLLOWER,
		) {
			this.isLoading = true;
			this.follower.isUpdated = false;

			const { errorMessage } = await postFollowGroup(userId, groupId, role);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
			this.follower.isUpdated = true;
		},

		async unfollow(userId: number, groupId: number) {
			this.isLoading = true;
			this.follower.isUpdated = false;

			const { errorMessage } = await deleteUnFollowGroup(userId, groupId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
			this.follower.isUpdated = true;
		},

		async getGroupRole(profileId: number, userGroupId: number) {
			this.isLoading = true;
			this.role.data = null;
			this.role.isNotMember = false;

			const { data, errorMessage } = await getUserGroupRole(profileId, userGroupId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (!data.value && !errorMessage.value) {
				// If user does not be member of the group,
				// return 204 no content
				this.role.isNotMember = true;
			} else if (data.value) {
				this.role.data = data.value;
			}
			this.isLoading = false;
		},

		async checkTicketOwnership(eventId: number) {
			this.isLoading = true;
			this.ownership.data = null;

			const { data, errorMessage } = await getTicketOwnership(eventId);

			if (errorMessage.value) {
				this.ownership.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.ownership.data = data.value;
			}
			this.isLoading = false;
		},

		async getTicketReceipt(userId: number, saleId: number) {
			this.userTicketReceipt.isLoading = true;
			this.userTicketReceipt.data = null;

			const { data, errorMessage } = await postTicketReceiptById(userId, saleId);

			if (errorMessage.value) {
				this.userTicketReceipt.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.userTicketReceipt.data = data.value;
			}
			this.userTicketReceipt.isLoading = false;
		},
	},
});

export default useProfileStore;
