<script setup lang="ts">
import type { AvatarSize } from '@/types/avatar';
import { RouterLink } from 'vue-router';
import placeHolderImage from '@/assets/svg/user-circle.svg';

const props = defineProps({
	imageUrl: {
		type: String as PropType<string | null>,
		default: null,
		required: true,
	},
	userId: {
		type: Number,
		default: null,
		required: true,
	},
	groupId: {
		type: Number,
		default: null,
	},
	alt: {
		type: String,
		default: null,
	},
	size: {
		type: String as PropType<AvatarSize>,
		default: AvatarSizes[2],
	},
	isPreviewMode: {
		type: Boolean,
		default: false,
	},
	hideImage: {
		type: Boolean,
		default: false,
	},
});

const profileStore = useProfileStore();

const routerLink = computed(() => {
	if (props.isPreviewMode) {
		return {};
	}

	if (props.groupId) {
		return {
			name: ROUTE.GROUP.NAME,
			params: { id: props.groupId },
		};
	}

	if (props.userId === profileStore.profile?.id) {
		return {
			name: ROUTE.PROFILE.NAME,
		};
	}

	return {
		name: ROUTE.USER.NAME,
		params: { id: props.userId },
	};
});

const internalImageUrl = computed(() => (props.imageUrl ? props.imageUrl : placeHolderImage));
</script>

<template>
  <div class="avatar-link-container">
    <AvatarImage
      v-if="!hideImage"
      class="avatar-image"
      :image-url="internalImageUrl"
      :alt="alt"
      :size="size"
    />
    <span
      v-if="$slots['prefix']"
      class="prefix-name text-regular"
    >
      <slot name="prefix" />
    </span>
    <component
      :is="isPreviewMode ? 'div' : RouterLink"
      class="avatar-link text-sm text-semibold cursor-pointer"
      :to="routerLink"
    >
      <slot name="content" />
    </component>
  </div>
</template>
<style lang="scss" scoped>
.avatar-link-container {
	display: flex;
	align-items: center;
	gap: spacings-get(1);

	.prefix-name {
		color: colors-get(gray, 700);
	}
}

.avatar-link {
	width: fit-content;
	display: flex;
	align-items: center;
	gap: spacings-get(2);
	color: colors-get(primary, 700);
	text-decoration: none;

	@include media-query-max(mobile) {
		padding-left: 0;
	}

	.avatar-image {
		aspect-ratio: 1;
	}
}
</style>
