<script setup lang="ts">
import type { ExtendedLocationDetail, LocationCoordinate } from '@/types/location';

const options = {
	fields: ['geometry', 'name', 'formatted_address'],
	strictBounds: false,
	types: ['(cities)'],
};

defineProps({
	disabled: {
		type: Boolean,
		default: false,
	},
	isTablet: {
		type: Boolean,
		default: false,
	},
	isTransparentBackground: {
		type: Boolean,
		default: false,
	},
});

const searchStore = useSearchStore();
const notificationStore = useNotificationStore();
const router = useRouter();
const { geocoder, initGeocoder, fetchLocation } = useGoogleMaps();
const { getGeolocationPermission, handleGeolocationPromptResponse } = useGeoLocation();

const isFocusOnLocationInput = ref(false);
const isFocusOnKeywordInput = ref(false);
const isShowLocationMenu = ref(false);
const isGrantedGeolocationPermission = ref(false);
const isSupportedGeolocation = ref(false);
const hasSelectedLocation = ref(false);
const locationInput = ref('');
const keywordInput = ref('');
const currentCoordinate = ref<LocationCoordinate | null>(null);
const currentLocationName = ref('');
const isLoadingGeoLocation = ref(false);

function handleSavePayload() {
	// Briefly delay closing the location menu to allow user to select an option
	setTimeout(() => {
		if (!isFocusOnLocationInput.value) {
			isShowLocationMenu.value = false;
		}
	}, 200);
}

function initializeSearch() {
	searchStore.isFetching = true;

	router.push({
		name: ROUTE.SEARCH.NAME,
	});
}

function handleLocationSelected(location: ExtendedLocationDetail) {
	locationInput.value = location.address;

	searchStore.resetSelectedLocation();
	searchStore.payload.selectedLocation.coordinates = location.coordinate;
	searchStore.payload.selectedLocation.countryCode = location.countryCode || '';
	searchStore.payload.selectedLocation.city = location.name;
	searchStore.payload.selectedLocation.country = location.address.split(', ').splice(1).join(', ');
	searchStore.payload.selectedLocation.name = location.address;

	hasSelectedLocation.value = true;
	handleSavePayload();
	initializeSearch();
}

function handleBlur() {
	handleSavePayload();
	isFocusOnKeywordInput.value = false;
	isFocusOnLocationInput.value = false;
}

function handlePressEnter() {
	// If location input contain a recent result from selecting a certain location in suggestion list,
	// or select current option, or select Everywhere option
	// Then, allow user to press enter and trigger search function
	// Otherwise, don't trigger search function when they press Enter
	if (
		!hasSelectedLocation.value &&
    locationInput.value !== '' &&
    locationInput.value !== CITY_NAME.EVERYWHERE
	) {
		return;
	}

	// Reset search payload
	if (!locationInput.value) {
		searchStore.resetSelectedLocation();
	}

	// Set location to 'Everywhere' if no location has been selected
	// and set keyword to user input
	searchStore.payload.keyword = keywordInput.value;
	if (!locationInput.value) {
		locationInput.value = CITY_NAME.EVERYWHERE;
	}

	handleSavePayload();
	initializeSearch();
}

function handleFocusOnLocationInput() {
	isFocusOnLocationInput.value = true;

	if (!locationInput.value) {
		isShowLocationMenu.value = true;
	}
}

function handleFocusOnKeywordInput() {
	isFocusOnKeywordInput.value = true;
}

async function initializeGeolocation(isSelectCurrentLocation = false) {
	if (!navigator.geolocation) {
		isSupportedGeolocation.value = false;
		notificationStore.showWarningNotification('Geolocation is not supported by this browser.');
		return;
	}

	isSupportedGeolocation.value = true;
	const permission = await getGeolocationPermission();

	if (permission.state === 'denied' && isSelectCurrentLocation) {
		notificationStore.showWarningNotification('Please allow location permission in your browser settings.');
	} else if (permission.state === 'granted' || permission.state === 'prompt') {
		await handleGeolocationPromptResponse(
			(position) => {
				currentCoordinate.value = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				};
				isGrantedGeolocationPermission.value = true;
			},
			() => {
				notificationStore.showWarningNotification('Please allow location permission in your browser settings.');
			},
		);
	}
}

async function handleSelectCurrentLocation() {
	isLoadingGeoLocation.value = true;

	// If current location has been stored in the current session
	// No need to check for geolocation data, or call Google Maps API
	// Otherwise, request user's current location
	if (searchStore.payload.currentLocation.countryCode) {
		// Retrieve current location from store
		// then, set current location as a location search payload
		searchStore.payload.selectedLocation.city = searchStore.payload.currentLocation.city;
		searchStore.payload.selectedLocation.country = searchStore.payload.currentLocation.country;
		searchStore.payload.selectedLocation.countryCode = searchStore.payload.currentLocation.countryCode;
		searchStore.payload.selectedLocation.coordinates = searchStore.payload.currentLocation.coordinates;
		searchStore.payload.selectedLocation.name = searchStore.payload.currentLocation.name;

		locationInput.value = searchStore.payload.currentLocation.name;
	} else {
		// Request user's current location if geolocation permission has not been granted
		if (!isGrantedGeolocationPermission.value) {
			await initializeGeolocation(true);
		}

		// Initialize Google Maps Geocoder if it has not been initialized yet
		if (!geocoder.value) {
			await initGeocoder();
		}

		let cityName = '';
		let countryName = '';
		let countryCode = '';

		try {
			const { cityName: city, countryName: country, countryCode: code } = await fetchLocation({}, currentCoordinate.value);
			cityName = city;
			countryName = country;
			countryCode = code;
		} catch {
			isLoadingGeoLocation.value = false;
			return;
		}

		// Expect output: "Bangkok, Thailand" or "New York, United States"
		currentLocationName.value = cityName ? `${cityName}, ${countryName}` : `${countryName}`;

		// Save the current location in store management
		searchStore.payload.currentLocation.city = cityName;
		searchStore.payload.currentLocation.country = countryName;
		searchStore.payload.currentLocation.countryCode = countryCode;
		searchStore.payload.currentLocation.coordinates = currentCoordinate.value;
		searchStore.payload.currentLocation.name = currentLocationName.value;

		// Set the current location as a search payload
		locationInput.value = currentLocationName.value;
		searchStore.payload.selectedLocation.city = cityName;
		searchStore.payload.selectedLocation.country = countryName;
		searchStore.payload.selectedLocation.countryCode = countryCode;
		searchStore.payload.selectedLocation.coordinates = currentCoordinate.value;
		searchStore.payload.selectedLocation.name = currentLocationName.value;
	}

	hasSelectedLocation.value = true;
	isLoadingGeoLocation.value = false;
	handleSavePayload();
	initializeSearch();
}

function handleSelectEverywhere() {
	locationInput.value = CITY_NAME.EVERYWHERE;

	searchStore.resetSelectedLocation();
	handleSavePayload();
	initializeSearch();
}

function handleLocationChange(value: string) {
	locationInput.value = value;
}

function handleKeywordChange(value: string) {
	keywordInput.value = value;
}

watch(() => locationInput.value, (newValue) => {
	if (newValue) {
		isShowLocationMenu.value = false;
	} else {
		isShowLocationMenu.value = true;
	}
});

watch(() => locationInput.value, (newLocationInput, prevLocationInput) => {
	// Determine if the new location input is a valid selection
	const isValidSelection =
    newLocationInput === CITY_NAME.EVERYWHERE ||
    newLocationInput === searchStore.payload.selectedLocation.name;

	// Update hasSelectedLocation accordingly
	if (newLocationInput !== prevLocationInput) {
		hasSelectedLocation.value = isValidSelection;
	}
});

onMounted(async () => {
	if (window.google && window.google.maps) {
		await initGeocoder();
	} else {
		window.addEventListener('load', async () => {
			if (window.google && window.google.maps) {
				await initGeocoder();
			}
		});
	}
});
</script>

<template>
  <div>
    <!-- Search box in tablet screen -->
    <SearchMenu
      v-if="isTablet"
      :location-input="locationInput"
      :keyword-input="keywordInput"
      :is-transparent-background="isTransparentBackground"
      :is-show-location-menu="isShowLocationMenu"
      :options="options"
      @on-location-input-change="handleLocationChange"
      @on-keyword-input-change="handleKeywordChange"
      @on-select-location="handleLocationSelected"
      @on-focus-location="handleFocusOnLocationInput"
      @on-focus-keyword="handleFocusOnKeywordInput"
      @on-select-current-location="handleSelectCurrentLocation"
      @on-select-everywhere="handleSelectEverywhere"
      @blur="handleBlur"
      @enter="handlePressEnter"
    />
    <!-- End Search box in tablet screen -->

    <!-- Search box in desktop screen -->
    <template v-else>
      <div
        :class="[
          'search-box-container',
          {
            '--focus': isFocusOnKeywordInput || isFocusOnLocationInput,
            '--disabled': disabled,
          }
        ]"
      >
        <div
          :class="['keyword-search-input', {
            '--disabled': disabled || isLoadingGeoLocation
          }]"
        >
          <SearchIcon
            class="icon"
            width="20"
            height="20"
            color="#667085"
          />
          <input
            ref="inputElement"
            v-model="keywordInput"
            placeholder="Search events"
            class="text-field"
            :disabled="disabled || isLoadingGeoLocation"
            @focus="handleFocusOnKeywordInput"
            @blur="handleBlur"
            @keydown.enter="handlePressEnter"
          >
        </div>

        <div class="location-search-input">
          <div class="input-wrapper">
            <div class="separator" />
            <BaseGoogleMapsAutoComplete
              id="menu-activator"
              v-model="locationInput"
              :options="options"
              placeholder="Search by city"
              :disabled="disabled || isLoadingGeoLocation"
              is-search-bar
              @on-select-location="handleLocationSelected"
              @focus="handleFocusOnLocationInput"
              @blur="handleBlur"
              @enter="handlePressEnter"
            >
              <template #icon>
                <MarkerPinIcon
                  class="icon"
                  color="#667085"
                />
              </template>
            </BaseGoogleMapsAutoComplete>

            <VList
              v-if="isShowLocationMenu"
              class="location-menu"
            >
              <VListItem
                min-height="36"
                class="text-regular"
                :ripple="false"
                @click="handleSelectCurrentLocation"
              >
                <button class="location-menu-item">
                  <MarkerPinAltIcon color="#475467" />
                  <p>Use my current location</p>
                </button>
              </VListItem>
              <VListItem
                min-height="36"
                class="text-regular"
                :ripple="false"
                @click="handleSelectEverywhere"
              >
                <button class="location-menu-item">
                  <GlobePinIcon color="#475467" />
                  <p>Everywhere</p>
                </button>
              </VListItem>
            </VList>
          </div>
        </div>
      </div>
    </template>
    <!-- End Search box in desktop screen -->
  </div>
</template>

<style scoped lang="scss">
.search-box-container {
  display: flex;
  border: 1px solid colors-get(gray, 300);
  @include border-radius-default;
  box-shadow: none;
  background-color: colors-get(base, white);

  &.--focus {
    color: colors-get(gray, 900);
    border: 1px solid colors-get(primary, 300);
    box-shadow:
      0px 0px 0px 4px #f4ebff,
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &.--disabled {
    background: colors-get(gray, 50);
  }

  .location-search-input {
    position: relative;

    :deep(.text-field-wrapper) {
      border-radius: rem(8) 0 0 rem(8);
      box-shadow: unset;
      border: 0;

      &.--focus {
        color: inherit !important;
        border: none !important;
        box-shadow: inherit !important;
      }
    }

    :deep(.text-field) {
      width: 100%;
      padding-left: spacings-get(2);
      padding-right: spacings-get(2);
    }

    .icon {
      margin-left: spacings-get(2);
      min-width: spacings-get(5);
    }

    .input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .separator {
        padding: rem(10) 0;
        border-left: 1px solid colors-get(gray, 300);
      }

      :deep(.text-field-wrapper) {
        position: relative;

        &.--disabled {
          border-radius: 0 rem(8) rem(8) 0 !important;
        }

        svg {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: 2;
        }

        .text-field {
          padding-left: rem(36);
        }
      }
    }
  }

  .keyword-search-input {
    display: flex;
    align-items: center;

    &.--disabled {
      background: colors-get(gray, 50);
      border-radius: rem(8) 0 0 rem(8);
    }

    .icon {
      margin: 0 spacings-get(2) 0 spacings-get(4);
      min-width: spacings-get(5);
    }

    .text-field {
      width: 100%;
      padding: rem(10) 0;
      @include border-radius-default;
      @include fonts-get(regular, text-md);
      color: colors-get(gray, 900);
      background: colors-get(base, white);
      outline: none;

      &:disabled {
        background: colors-get(gray, 50);
        color: colors-get(gray, 500);

        &::placeholder {
          color: colors-get(gray, 400);
        }
      }

      &::placeholder {
        color: colors-get(gray, 400);
      }
    }
  }
}
</style>