import type {
	AuthConnectSnsAccountRequest,
	AuthForm,
	AuthResponse,
	AuthSnsConnectMetaData,
	SignInAppleRequest,
	SignInWithSnsRequest,
	SnsConnectPayload,
	SnsConnectResponse,
	GoogleAuthToken,
} from '@/types/auth';
import axios, { type AxiosResponse } from 'axios';

const REDIRECT_URL = import.meta.env.VITE_LEGACY_PEATIX_URL;
const PEATIX_TOKEN = import.meta.env.VITE_PEATIX_TOKEN;

interface AuthStore {
	isAuthenticating: boolean
	isLoggedIn: boolean
	isLoading: boolean
	isSendVerifyCode: boolean
	isEmailValid: boolean
	errorMessage: string
	snsErrorMessage: string
	snsConnectMetaData: AuthSnsConnectMetaData | null
	snsConnectErrorMessage: string
	identityToken: string
	snsConnect: SnsConnectResponse | null
	authMethod: string
}

const useAuthStore = defineStore('auth', {
	state: (): AuthStore => ({
		isAuthenticating: false,
		isLoggedIn: !!getLocalStorage(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN),
		isLoading: false,
		isSendVerifyCode: false,
		isEmailValid: false,
		errorMessage: '',
		snsErrorMessage: '',
		snsConnectMetaData: null,
		snsConnectErrorMessage: '',
		identityToken: '',
		snsConnect: null,
		authMethod: '',
	}),

	getters: {
		googleId: (state): number | null => {
			if (!state.snsConnect) {
				return null;
			}
			const googleConnect = state.snsConnect.data.find((sns) => sns.service === AUTH_SERVICE.GOOGLE);
			return googleConnect ? googleConnect.id : null;
		},

		appleId: (state): number | null => {
			if (!state.snsConnect) {
				return null;
			}
			const appleConnect = state.snsConnect.data.find((sns) => sns.service === AUTH_SERVICE.APPLE);
			return appleConnect ? appleConnect.id : null;
		},

		isGoogleConnected: (state): boolean | null => {
			if (!state.snsConnect) {
				return null;
			}
			return !!state.snsConnect.data.find((sns) => sns.service === AUTH_SERVICE.GOOGLE);
		},

		isAppleConnected: (state): boolean | null => {
			if (!state.snsConnect) {
				return null;
			}
			return !!state.snsConnect.data.find((sns) => sns.service === AUTH_SERVICE.APPLE);
		},
	},

	actions: {
		async generateAuthToken(code?: string) {
			const response: AxiosResponse<GoogleAuthToken> = await axios({
				method: 'post',
				url: 'https://www.googleapis.com/oauth2/v4/token',
				data: {
					code,
					client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
					client_secret: import.meta.env.VITE_GOOGLE_CLIENT_SECRET,
					grant_type: 'authorization_code',
					redirect_uri: import.meta.env.VITE_GOOGLE_REDIRECT_URI,
				},
			});

			return response;
		},

		async authWithGoogle(payload: SignInWithSnsRequest) {
			this.setProcessingRequestState();
			this.isAuthenticating = true;

			// Use Authorization Code from google sign in to get Bearer token before request Peatix api
			let authToken = '';
			try {
				const response = await this.generateAuthToken(payload.secret);
				authToken = response.data.id_token;
			} catch {
				// TODO: Provide message
				this.errorMessage = 'Cannot connect with Google';
			}

			const { data, errorMessage, errorMetadata } = await postSignInWithSnsAPI({
				...payload,
				create: 1,
				secret: authToken,
			});

			this.authMethod = GTM_AUTH_METHOD_PARAMS.GOOGLE;
			if (errorMessage.value) {
				this.isLoading = false;

				if (errorMetadata.value && errorMetadata.value.email_exists) {
					this.snsConnectMetaData = {
						availableSigninRealms: errorMetadata.value.available_signin_realms,
						email: errorMetadata.value.email,
						snsSecret: authToken,
					};
					return;
				}

				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.setLocalStorageToken(data.value);
			}

			this.isLoading = false;
		},

		async authWithApple(payload: SignInAppleRequest) {
			this.setProcessingRequestState();
			this.isAuthenticating = true;

			const { data, errorMessage, errorMetadata } = await postSignInWithSnsAPI({
				...payload,
				create: 1,
			});

			this.authMethod = GTM_AUTH_METHOD_PARAMS.APPLE;
			if (errorMessage.value) {
				this.isLoading = false;

				if (errorMetadata.value && errorMetadata.value.email_exists) {
					this.snsConnectMetaData = {
						availableSigninRealms: errorMetadata.value.available_signin_realms,
						email: errorMetadata.value.email,
						snsSecret: payload.secret || '',
						snsToken: payload.token || '',
						user: payload.user,
					};
					return;
				}

				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.setLocalStorageToken(data.value);
			}

			this.isLoading = false;
		},

		async validateEmail(email: string) {
			this.setProcessingRequestState();
			this.isAuthenticating = true;

			const { data: result, errorMessage } = await getValidateEmailAPI(email);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (result.value) {
				const isValid = !!result.value.data.valid;
				this.isEmailValid = !!result.value.data.valid;
				if (!isValid) {
					this.errorMessage = `We couldn't find an account with the information you entered. Please try again or create a new account.`;
				}
			}

			this.isLoading = false;
		},

		async registerNewUserWithEmail(data: AuthForm) {
			this.setProcessingRequestState();
			this.isAuthenticating = true;

			const { data: result, errorMessage } = await postRegisterWithEmailAPI({
				email: data.email,
				nickname: data.username,
				type: PEAZ_STANDARD,
			});

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value === 'Bad Request' ? 'This email cannot be used to register.' : errorMessage.value;
			}

			if (result.value) {
				this.isSendVerifyCode = true;
			}

			this.isLoading = false;
		},

		async sendVerificationCode(email: string, codeType = AUTH_CODE_TYPE.SIGN_IN) {
			this.setProcessingRequestState();

			const { data, errorMessage } = await postSendVerificationCodeAPI({
				delivery: 'email',
				keyId: email,
				codeType,
			});

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.isSendVerifyCode = true;
			}

			this.isLoading = false;
		},

		async authWithCode(code: string, email: string, snsConnectPayload?: AuthConnectSnsAccountRequest) {
			this.setProcessingRequestState();

			const { data: resultVerifyCode, errorMessage: errorVerifyCode } = await postVerifyCodeAPI({
				code,
				keyId: email,
			});

			if (errorVerifyCode.value || !resultVerifyCode.value) {
				// TODO: i18n
				this.errorMessage = 'The sign in code you entered is incorrect. Please try again.';
				this.isLoading = false;
				return;
			}

			const { data, errorMessage } = await postSignInWithMfaTokenAPI({
				username: email,
				authCode: resultVerifyCode.value.codeToken,
				...(snsConnectPayload ? {
					connect_sns_account: snsConnectPayload,
				} : {}),
			});

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.setLocalStorageToken(data.value);
				if (data.value.metadata && data.value.metadata.connect_sns_account.status === 'failed') {
					// Fail to connect sns
					this.snsConnectErrorMessage = `This ${snsConnectPayload?.service} account cannot be used to connect to your Peatix account.`;
				}

				if (!this.snsConnectMetaData) {
					this.authMethod = GTM_AUTH_METHOD_PARAMS.MAGIC_CODE;
				}
			}

			this.isLoading = false;
		},

		async verifyAccountByCode(code: string, email: string, identityToken?: string) {
			this.setProcessingRequestState();

			const payload = {
				code,
				keyId: email,
			};
			const { data, errorMessage: errorVerifyCode } = await postVerifyCodeAPI(payload, identityToken);

			if (errorVerifyCode.value) {
				this.isLoading = false;
				// TODO: i18n
				this.errorMessage = 'The verification code you entered is incorrect. Please try again.';
			}

			if (data.value?.identityToken) {
				this.identityToken = data.value.identityToken;
			}

			this.isLoading = false;
		},

		async signout(userId: number) {
			this.setProcessingRequestState();

			const { errorMessage } = await deleteUserSession(userId);

			if (errorMessage.value) {
				// TODO: i18n
				this.errorMessage = COMMON_ERROR_MESSAGE.TRY_AGAIN;
			}

			this.isLoading = false;
		},

		setProcessingRequestState() {
			this.isLoading = true;
			this.errorMessage = '';
			this.isSendVerifyCode = false;
			this.isEmailValid = false;
			this.snsErrorMessage = '';
			this.snsConnectErrorMessage = '';
		},

		async logout(userId: number) {
			await this.signout(userId);

			const profileStore = useProfileStore();
			const { trackEvent } = useGtm();

			if (this.errorMessage) {
				trackEvent({
					user_id: profileStore.profileId,
					event: GTM_EVENT_NAME.USER_SIGN_OUT_FAILURE,
				});
			} else {
				this.removeUser();
				trackEvent({
					user_id: profileStore.profileId,
					event: GTM_EVENT_NAME.USER_SIGN_OUT_SUCCESS,
				});
				profileStore.removeProfile();
			}
		},

		setLocalStorageToken(userAuth: AuthResponse) {
			setLocalStorage(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN, userAuth.access_token);
			setLocalStorage(LOCAL_STORAGE_ITEMS.USER_SECRET, userAuth.user_secret);
			this.isLoggedIn = true;
		},

		removeUser() {
			this.isLoggedIn = false;
			this.authMethod = '';
			removeLocalStorage(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN);
			removeLocalStorage(LOCAL_STORAGE_ITEMS.USER_SECRET);
			removeLocalStorage(LOCAL_STORAGE_ITEMS.USER);
		},

		async getSnsConnect(userId: number) {
			this.isLoading = true;
			this.errorMessage = '';

			const { data, errorMessage } = await getSnsConnectAPI(userId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.snsConnect = data.value;
			}

			this.isLoading = false;
		},

		async connectWithSns(userId: number, payload: SnsConnectPayload) {
			this.setProcessingRequestState();

			// Use Authorization Code from google sign in to get Bearer token before request Peatix api
			let authToken = '';
			if (payload.service === AUTH_SERVICE.GOOGLE) {
				try {
					const response = await this.generateAuthToken(payload.secret);
					authToken = response.data.id_token;
				} catch {
					// TODO: Provide message
					this.errorMessage = 'Cannot connect with Google';
				}
			}

			const { errorMessage } = await postSnsConnectAPI(
				userId,
				{
					...payload,
					secret: payload.service === AUTH_SERVICE.GOOGLE ? authToken : payload.secret,
				},
			);

			if (errorMessage.value) {
				// TODO: i18n
				this.errorMessage = COMMON_ERROR_MESSAGE.TRY_AGAIN;
			}

			this.isLoading = false;
		},

		async deleteSnsConnect(userId: number, connectId: number) {
			this.setProcessingRequestState();

			const { errorMessage } = await deleteSnsConnectAPI(userId, connectId);

			if (errorMessage.value) {
				// TODO: i18n
				this.errorMessage = COMMON_ERROR_MESSAGE.TRY_AGAIN;
			}

			this.isLoading = false;
		},

		switchToPeatix() {
			const userAccessToken = getLocalStorage('access_token');
			openLink(`${REDIRECT_URL}/auth-session-transfer?token=${userAccessToken}-${PEATIX_TOKEN}`, '_self');
			removeLocalStorage(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN);
			removeLocalStorage(LOCAL_STORAGE_ITEMS.USER_SECRET);
			removeLocalStorage(LOCAL_STORAGE_ITEMS.USER);
		},

		proceedSecondSignIn() {
			const userAccessToken = getLocalStorage('access_token');
			openLink(`${REDIRECT_URL}/web-sessions?token=${userAccessToken}-${PEATIX_TOKEN}`, '_self');
		},
	},
});

export default useAuthStore;
