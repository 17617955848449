<script setup lang="ts">
import type { GROUP_ROLE as GROUP_ROLE_TYPE } from '@/enums/group';

const props = defineProps({
	userId: {
		type: Number as PropType<number | null>,
		default: null,
		required: true,
	},
	groupId: {
		type: Number as PropType<number | null>,
		default: null,
	},
	role: {
		type: String as PropType<GROUP_ROLE_TYPE | null>,
		default: '',
	},
	isEventPage: {
		type: Boolean,
		default: false,
	},
	isLoggedIn: {
		type: Boolean,
		default: false,
	},
	isSmallSize: {
		type: Boolean,
		default: false,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onSignIn'): void
	(e: 'onUpdate'): void
}>();

const userStore = useUserStore();
const notificationStore = useNotificationStore();

const userRole = computed({
	get: () => props.role,
	set: (value) => value,
});

const isFollower = computed(() => {
	// For now, we doesn't utilize subsription feature in Peasy.
	// Thus, we considered both 'member' and 'follower' the same role for now
	return userRole.value === GROUP_ROLE.FOLLOWER || userRole.value === GROUP_ROLE.MEMBER;
});

const isOwner = computed(() => {
	return userRole.value === GROUP_ROLE.OWNER;
});

const isShowFollowBtn = computed(() => !isFollower.value && !isOwner.value && !userRole.value);
const isShowFollowingBtn = computed(() => isFollower.value && !isOwner.value);

async function handleFollowGroup() {
	if (!props.isLoggedIn) {
		emit('onSignIn');
		return;
	}

	if (!props.userId || !props.groupId) {
		return;
	}

	if (props.userId && props.groupId) {
		await userStore.follow(
			props.userId,
			props.groupId,
		);
	}

	if (userStore.errorMessage) {
		notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.REFRESH_PAGE);
		return;
	}
	notificationStore.showSuccessNotification('Successfully followed this group.');
	await userStore.getGroupRole(props.userId, props.groupId);
	emit('onUpdate');
}

async function handleUnFollowGroup() {
	if (!props.userId || !props.groupId) {
		return;
	}

	if (props.userId && props.groupId) {
		await userStore.unfollow(
			props.userId,
			props.groupId,
		);
	}

	if (userStore.errorMessage) {
		notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.REFRESH_PAGE);
		return;
	}
	notificationStore.showSuccessNotification('Successfully unfollowed this group.');
	await userStore.getGroupRole(props.userId, props.groupId);
	emit('onUpdate');
}

defineExpose({
	handleFollowGroup,
});
</script>

<template>
  <template v-if="isSmallSize">
    <BaseButton
      v-if="isShowFollowBtn"
      class="small-follow-btn"
      color="gray"
      variant="subtle"
      size="sm"
      :disabled="isLoading || disabled"
      @click="handleFollowGroup"
    >
      <UserPlusIcon />
      <template #tooltip>
        <p>Follow</p>
      </template>
    </BaseButton>
    <BaseButton
      v-else-if="isShowFollowingBtn"
      class="small-follow-btn"
      color="gray"
      variant="subtle"
      size="sm"
      :disabled="isLoading || disabled"
      @click="handleUnFollowGroup"
    >
      <UserCheckIcon />
      <template #tooltip>
        <p>Unfollow</p>
      </template>
    </BaseButton>
  </template>

  <template v-else>
    <BaseButton
      v-if="isShowFollowBtn"
      :class="[{ 'follow-btn': !isEventPage }]"
      :color="isEventPage? 'gray' : 'primary'"
      :variant="isEventPage? 'outlined' : 'solid'"
      size="sm"
      :disabled="isLoading || disabled"
      @click="handleFollowGroup"
    >
      <span>Follow</span>
      <template #tooltip>
        <p>Follow</p>
      </template>
    </BaseButton>
    <BaseButton
      v-else-if="isShowFollowingBtn"
      class="follow-btn"
      size="sm"
      color="gray"
      variant="outlined"
      :disabled="isLoading || disabled"
      @click="handleUnFollowGroup"
    >
      <span>Following</span>
      <template #tooltip>
        <p>Unfollow</p>
      </template>
    </BaseButton>
  </template>
</template>

<style lang="scss" scoped>
.small-follow-btn {
	padding: spacings-get(2);
}

.follow-btn {
	width: rem(93);
}
</style>