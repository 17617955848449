import type { GROUP_IMAGE_FIELD as GROUP_IMAGE_FIELD_TYPE } from '@/enums/group';
import type { ErrorResponse, FilterInfo } from '@/types/common';
import type { GroupImageFile, GroupInfo, UpdatedGroupInfoResponse, CreatedGroupInfoResponse } from '@/types/group';
import type {
	GroupUploadFilesResponse,
	UpdatedProfileInfo,
	ProfileMessageInfo,
	FollowerListResponse,
	SocialProfile,
	FollowingListResponse,
	CountryTimezoneRequest,
	TicketPurchasesHistoryResponse,
	AddCreditCardResponse,
	UserProfileResponse,
	FollowingFitlerInfo,
} from '@/types/profile';

export const getMyAccount = () => {
	return useRequest<UserProfileResponse>(`${USERS_ENDPOINT}/me/account/profile`, REQUEST_METHOD.GET);
};

export const getUserPeasyGroups = (userId: number) => {
	return useRequest<{ data: CreatedGroupInfoResponse[] }>(`${USER_GROUPS}`, REQUEST_METHOD.GET, {}, {
		ownerId: userId,
		type: GROUP_TYPE.PEASY_GROUP,
	});
};

export const postCreateUserGroup = (groupName: string, description?: string) => {
	return useRequest<CreatedGroupInfoResponse>(`${USER_GROUPS}`, REQUEST_METHOD.POST, {
		name: groupName,
		...(description ? { description } : {}),
		type: GROUP_TYPE.PEASY_GROUP,
	});
};

export const getGroupInfoByGroupId = (groupId: number) => {
	return useRequest<GroupInfo, ErrorResponse>(`${USER_GROUPS}/${groupId}/info`, REQUEST_METHOD.GET);
};

export const postGroupCoverImageAPI = (groupId: number, file: GroupImageFile, fields: GROUP_IMAGE_FIELD_TYPE[]) => {
	const formData = new FormData();
	if (fields.includes(GROUP_IMAGE_FIELD.COVER_IMAGE) && fields.includes(GROUP_IMAGE_FIELD.LOGO)) {
		formData.append(GROUP_IMAGE_FIELD.COVER_IMAGE, file.cover || '');
		formData.append(GROUP_IMAGE_FIELD.LOGO, file.logo || '');
	} else if (fields.includes(GROUP_IMAGE_FIELD.COVER_IMAGE) && !fields.includes(GROUP_IMAGE_FIELD.LOGO)) {
		formData.append(GROUP_IMAGE_FIELD.COVER_IMAGE, file.cover || '');
	} else if (fields.includes(GROUP_IMAGE_FIELD.LOGO) && !fields.includes(GROUP_IMAGE_FIELD.COVER_IMAGE)) {
		formData.append(GROUP_IMAGE_FIELD.LOGO, file.logo || '');
	}

	return useRequest<GroupUploadFilesResponse, ErrorResponse>(
		`${USER_GROUPS}/${groupId}/uploads`,
		REQUEST_METHOD.POST,
		formData,
		{},
		{
			headers: {
				'Content-Type': 'multipart/form-data',
				Accept: 'application/json',
			},
		},
	);
};

export const deleteGroupImageAPI = (groupId: number, field: GROUP_IMAGE_FIELD_TYPE) => {
	return useRequest<{id: string}, ErrorResponse>(`${USER_GROUPS}/${groupId}/uploads`, REQUEST_METHOD.DELETE, {}, {
		field,
	});
};

export const patchUpdateGroupInfo = (groupId: number, groupInfo: Partial<GroupInfo>) => {
	return useRequest<UpdatedGroupInfoResponse, ErrorResponse>(`${USER_GROUPS}/${groupId}`, REQUEST_METHOD.PATCH, groupInfo);
};

export const patchUpdateProfileInfo = (profileInfo: UpdatedProfileInfo) => {
	return useRequest<UserProfileResponse, ErrorResponse>(
		`${USERS_ENDPOINT}/me/account/profile`,
		REQUEST_METHOD.PATCH,
		{
			nickname: profileInfo.name,
			description: profileInfo.description,
		},
	);
};

export const postUploadProfileImageAPI = (file: File | Blob) => {
	const formData = new FormData();
	formData.append('avatar', file || '');

	return useRequest<{}, ErrorResponse>(
		`${USERS_ENDPOINT}/me/account/uploads`,
		REQUEST_METHOD.POST,
		formData,
		{},
		{
			headers: {
				'Content-Type': 'multipart/form-data',
				Accept: 'application/json',
			},
		},
	);
};

export const deleteProfileImageAPI = () => {
	return useRequest<{}, ErrorResponse>(`${USERS_ENDPOINT}/me/account/uploads`, REQUEST_METHOD.DELETE);
};

export const postMessageToFollower = (groupId: number, messageInfo: ProfileMessageInfo) => {
	return useRequest<{}, ErrorResponse>(`${USER_GROUPS}/${groupId}/messages`, REQUEST_METHOD.POST, messageInfo);
};

export const getFollowerListByUserId = (userGroupId: number, filter: FilterInfo) => {
	return useRequest<FollowerListResponse, ErrorResponse>(`${USER_GROUPS}/${userGroupId}/followers`, REQUEST_METHOD.GET, {}, filter);
};

export const getFollowingListByUserId = (userId: number, filter: FollowingFitlerInfo) => {
	return useRequest<FollowingListResponse, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/groups`, REQUEST_METHOD.GET, {}, filter);
};

export const getSocialProfileByUserId = (userId: number) => {
	return useRequest<{ data: SocialProfile[] }, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/sns-profiles`, REQUEST_METHOD.GET);
};

export const putUpdateSocialProfile = (userId: number, socialProfile: SocialProfile[]) => {
	return useRequest<{ data: SocialProfile[] }, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/sns-profiles`, REQUEST_METHOD.PUT, {
		data: socialProfile,
	});
};

export const patchUpdateProfileEmail = (userId: number, email: string, identityToken: string) => {
	return useRequest<UserProfileResponse, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/profile`, REQUEST_METHOD.PATCH, {
		email,
	}, {}, {
		headers: {
			'X-Peatix-Identity-Validation-Token': identityToken,
		},
	});
};

export const patchUpdateProfileCountryTimezone = (userId: number, payload: CountryTimezoneRequest) => {
	return useRequest<UserProfileResponse, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/profile`, REQUEST_METHOD.PATCH, payload);
};

export const getTicketPurchasesHistory = (filterInfo?: FilterInfo) => {
	const filter = {
		...(filterInfo?.sortBy && {sortBy: filterInfo?.sortBy}),
		...(filterInfo?.itemPerPage && {itemPerPage: filterInfo?.itemPerPage}),
		...(filterInfo?.currentPage && {currentPage: filterInfo?.currentPage}),
	};
	return useRequest<TicketPurchasesHistoryResponse, ErrorResponse>(`${USERS_ENDPOINT}/me/account/ticket-purchases`, REQUEST_METHOD.GET, {}, filter);
};

export const postAddCreditCard = () => {
	return useRequest<AddCreditCardResponse, ErrorResponse>(`${USERS_ENDPOINT}/me/account/payment-cards`, REQUEST_METHOD.POST);
};