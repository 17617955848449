<script setup lang="ts">
import type { GroupInList } from '@/types/group';

const props = defineProps({
	data: {
		type: Object as PropType<GroupInList>,
		required: true,
	},
	isPrivateView: {
		type: Boolean,
		default: false,
	},
});

const followerText = computed(() => {
	return `${props.data.followerNumber} follower${getPluralSuffix(props.data.followerNumber)}`;
});

const activeEventText = computed(() => {
	const eventName = props.isPrivateView ? 'active' : 'upcoming';
	return `${props.data.activeEventNumber} ${eventName} event${getPluralSuffix(props.data.activeEventNumber)}`;
});
</script>

<template>
  <div class="group-detail-item">
    <div class="cover-image">
      <RouterLink
        :to="{
          name: ROUTE.GROUP.NAME,
          params: {
            id: data.id
          }
        }"
        target="_blank"
      >
        <img
          v-if="data.cover"
          :src="data.cover"
        >
        <div
          v-else
          class="placeholder"
        >
          <PeatixGrayIcon />
        </div>
      </RouterLink>
    </div>
    <div class="header-container">
      <RouterLink
        class="title"
        :to="{
          name: ROUTE.GROUP.NAME,
          params: {
            id: data.id
          }
        }"
        target="_blank"
      >
        <AvatarImage
          class="logo-image"
          :image-url="data.logo"
          :alt="data.name"
          :size="AvatarSizes[5]"
        />
      </RouterLink>
      <slot name="miscBtn" />
    </div>
    <div class="title-container">
      <RouterLink
        class="title"
        :to="{
          name: ROUTE.GROUP.NAME,
          params: {
            id: data.id
          }
        }"
        target="_blank"
      >
        {{ data.name }}
      </RouterLink>
      <div class="number-section">
        <p class="follower-number">
          {{ followerText }}
        </p>
        <span
          v-if="data.activeEventNumber > 0"
          class="active-number"
        >
          <RouterLink
            :to="{
              name: ROUTE.GROUP.NAME,
              params: {
                id: data.id
              },
              hash: '#hosting-list'
            }"
            target="_blank"
          >
            <span>{{ activeEventText }}</span>
          </RouterLink>
        </span>
      </div>
    </div>
    <div class="detail-container">
      <p
        v-if="data.description"
        class="sub-title ellipsis text-wrap"
      >
        {{ data.description }}
      </p>
    </div>
    <div class="button-container">
      <slot name="actionBtn" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.group-detail-item {
  position: relative;
  min-height: rem(338);
  max-width: rem(320);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: spacings-get(3);
  padding: 0 spacings-get(4) spacings-get(4);
  @include border-radius-default;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);

  @include media-query-max(mobile) {
    max-width: none;
    height: unset;
  }

  .title {
    text-decoration: initial;
    color: colors-get(gray, 900);
    @include fonts-get(medium, text-sm);
  }

  .sub-title {
    color: colors-get(gray, 900);
    @include fonts-get(regular, text-sm);
    word-break: break-word;
  }

  .cover-image {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    top: 0;

    .placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: colors-get(gray, 200);
    }

    img {
      width: 100%;
      height: 100px;
      object-fit: cover;
      object-position: top;
    }

    img,
    .placeholder {
      border-radius: spacings-get(2) spacings-get(2) 0 0;
    }

    .placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: colors-get(gray, 200);
      border-radius: rem(4);
    }

    @include media-query-max(mobile) {

      img,
      .placeholder {
        height: 100%;
      }
    }
  }

  .header-container {
    position: relative;
    margin-top: rem(104);
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @include media-query-max(mobile) {
      margin-top: rem(128);
      justify-content: space-between;
    }

    .logo-image {
      position: absolute;
      top: -20px;
      left: 0;
      width: rem(60);
      height: rem(60);

      @include media-query-max(mobile) {
        position: static;
      }

      :deep(img) {
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      }
    }
  }

  .title-container {
    a {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .number-section {
      display: flex;
      gap: spacings-get(3);

      .follower-number {
        color: colors-get(gray, 500);
        @include fonts-get(regular, text-xs);
      }

      .active-number {
        color: colors-get(primary, 600);
        @include fonts-get(regular, text-xs);
      }
    }
  }

  .detail-container {
    display: flex;
    flex-grow: 1;
    height: rem(60);
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: spacings-get(3);

    :deep(a) {
      flex-grow: 1;
    }

    :deep(button) {
      width: 100%;
    }
  }
}
</style>
