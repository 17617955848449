export function useGeoLocation() {
	async function handleGeolocationPromptResponse(successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null, geolocationOptions?: PositionOptions) {
		const defaultGeolocationOptions = {
			timeout: 5000, //  If the response takes longer than this time, the browser will throw an error.
			maximumAge: 0, // Don't cache location
		};

		return new Promise((resolve) => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					successCallback(position);
					resolve(position);
				},
				errorCallback,
				{
					...defaultGeolocationOptions,
					...geolocationOptions,
				},
			);
		});
	}

	async function getGeolocationPermission() {
		return await navigator.permissions.query({ name: 'geolocation' });
	}

	return {
		handleGeolocationPromptResponse,
		getGeolocationPermission,
	};
}