<script setup lang="ts">
import type { Timezone } from '@/types/country-timezone';
import type { CountryTimezoneRequest } from '@/types/profile';
import useVuelidate from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
import ChevronUpIcon from '@/components/icons/ChevronUpIcon.vue';
import switchSitePreview from '@/assets/images/switch-site-preview.png';

const VERIFY_BUTTON_NAME = 'Verify';
const CONTINUE_BUTTON_NAME = 'Continue';

const countryStore = useCountryStore();
const profileStore = useProfileStore();
const notificationStore = useNotificationStore();
const authStore = useAuthStore();
const { isMobile } = useWindowResize();
const { trackEvent } = useGtm();

const selectedCountry = ref();
const selectedTimezone = ref();
const verifyEmailDlg = ref();
const verifyNewEmailDlg = ref();
const currentUserEmail = ref();
const newUserEmail = ref();
const isShowConfirmChangeEmailDialog = ref(false);
const isShowChangeEmailDialog = ref(false);
const isAgreeWithTerms = ref(false);
const authCodeType = ref();
const confirmButtonName = ref();
const isShowConfirmCountryDialog = ref(false);
const isShowAppleLoginFailedDialog = ref(false);
const isTimezoneDropdownOpen = ref(false);
const defaultDatetime = ref(convertDateTimeToUtc(new Date()));

const profile = computed(() => profileStore.profile);
const isEmailVerified = computed(() => profileStore.profile?.isVerified);
const countryOptions = computed(() => countryStore.countryList);
const isLoading = computed(() => profileStore.isLoading || countryStore.isLoading || authStore.isLoading);
const isFetching = computed(() => isLoading.value && !authStore.snsConnect?.data);
const chevronIconComponent = computed(() => (isTimezoneDropdownOpen.value ? ChevronUpIcon : ChevronDownIcon));
const countryId = computed(() => {
	const matchItem = countryOptions.value.find((item) => item.id === selectedCountry.value);
	return matchItem?.id || '';
});
const { timezones } = useTimezoneList(defaultDatetime, countryId);
const displaySelectedTimezoneText = computed(() => {
	const matchTimezoneObject = timezones.value.find((tz) => selectedTimezone.value === tz.timezone);
	return matchTimezoneObject ? convertDisplaySelectedTimezone(matchTimezoneObject) : '';
});

const rules = computed(() => {
	if (!isShowChangeEmailDialog.value) {
		return {
			newUserEmail: {},
		};
	}

	return {
		newUserEmail: {
			required: helpers.withMessage('Please enter your email address.', required),
			email: helpers.withMessage('Please enter a valid email address.', email),
		},
	};
});

const $externalResults = reactive<{ newUserEmail: string[] }>({
	newUserEmail: [],
});

const v$ = useVuelidate(rules, {
	newUserEmail,
}, { $externalResults });

function trackSuccessEvent(snsMethod: string) {
	trackEvent({
		user_id: profileStore.profileId,
		sns_method: snsMethod,
		event: GTM_EVENT_NAME.SNS_SETTINGS_CONNECT_SUCCESS,
	});
}

function trackFailedEvent(snsMethod: string) {
	trackEvent({
		user_id: profileStore.profileId,
		sns_method: snsMethod,
		event: GTM_EVENT_NAME.SNS_SETTINGS_CONNECT_FAILURE,
	});
}

async function handleCheckSnsConnect(id: number) {
	if (!id) {
		return;
	}

	await authStore.getSnsConnect(id);
}

async function handleGoogleConnect(authCode: string) {
	if (!profile.value?.id) {
		return;
	}

	await authStore.connectWithSns(
		profile.value.id,
		{
			service: AUTH_SERVICE.GOOGLE,
			secret: authCode,
		},
	);

	if (authStore.errorMessage) {
		notificationStore.showErrorNotification(authStore.errorMessage);
		trackFailedEvent(GTM_CONNECTING_SNS_METHOD_PARAMS.GOOGLE);
	} else {
		notificationStore.showSuccessNotification('Successfully connected to your Google account.');
		trackSuccessEvent(GTM_CONNECTING_SNS_METHOD_PARAMS.GOOGLE);
	}

	handleCheckSnsConnect(profile.value.id);
}

async function handleAppleConnect(data: AppleSignInAPI.SignInResponseI) {
	if (!profile.value?.id) {
		return;
	}

	await authStore.connectWithSns(
		profile.value.id,
		{
			service: AUTH_SERVICE.APPLE,
			secret: data.authorization.code,
			token: data.authorization.id_token,
			client_id: import.meta.env.VITE_APPLE_CLIENT_ID,
			user: getDefaultUser(data),
		},
	);

	if (authStore.errorMessage) {
		notificationStore.showErrorNotification(authStore.errorMessage);
		trackFailedEvent(GTM_CONNECTING_SNS_METHOD_PARAMS.APPLE);
	} else {
		notificationStore.showSuccessNotification('Successfully connected to your Apple account.');
		trackSuccessEvent(GTM_CONNECTING_SNS_METHOD_PARAMS.APPLE);
	}

	handleCheckSnsConnect(profile.value.id);
}

async function handleGoogleLoginSuccess(response: google.accounts.oauth2.CodeResponse) {
	if (response?.error) {
		return;
	}
	await handleGoogleConnect(response.code);
}

async function handleAppleLoginSuccess() {
	const data = await AppleID.auth.signIn();
	await handleAppleConnect(data);
}

async function handleAppleLoginFailed(response: any) {
	if (response.detail.error === 'popup_blocked_by_browser') {
		isShowAppleLoginFailedDialog.value = true;
	}
}

const { appleSignIn, googleClient } = useSnsConnect(
	handleAppleLoginSuccess,
	handleAppleLoginFailed,
	handleGoogleLoginSuccess,
);

function handleGoogleLoginClick() {
	// Trigger google sign in prompt
	googleClient.value?.requestCode();
}

function handleAppleLoginClick() {
	appleSignIn.value?.click();
}

async function handleVerifyAccount() {
	authCodeType.value = AUTH_CODE_TYPE.ORGANIZER;
	confirmButtonName.value = VERIFY_BUTTON_NAME;
	const isVerified = await verifyEmailDlg.value.open();
	if (isVerified) {
		await profileStore.myAccountInfo();
		notificationStore.showSuccessNotification('Successfully verified your account.');
	}
}

async function handleVerifyCurrentEmail() {
	isShowConfirmChangeEmailDialog.value = false;
	authCodeType.value = AUTH_CODE_TYPE.IDENTITY_VALIDATION;
	confirmButtonName.value = CONTINUE_BUTTON_NAME;
	const isVerified = await verifyEmailDlg.value.open();
	if (isVerified) {
		isShowChangeEmailDialog.value = true;
	}
}

async function sendCodeToNewEmail() {
	if (profile.value?.id) {
		await profileStore.updateProfileEmail(profile.value?.id, newUserEmail.value, authStore.identityToken);
	}
}

async function handleUpdateEmail() {
	v$.value.$touch();

	if (v$.value.$error) {
		return;
	}

	await sendCodeToNewEmail();
	if (profileStore.errorUpdateEmailMessage) {
		return;
	}

	isShowChangeEmailDialog.value = false;

	const isVerified = await verifyNewEmailDlg.value.open();
	if (isVerified) {
		await profileStore.myAccountInfo();
		notificationStore.showSuccessNotification('Successfully verified your account and changed your email address.');
	}
}

function handleCloseChangeEmailDialog() {
	isShowChangeEmailDialog.value = false;
}

function handleSelectTimezone(data: Timezone) {
	selectedTimezone.value = data.timezone;
}

function handleTimezoneDropdownMenuChange(isOpen: boolean) {
	isTimezoneDropdownOpen.value = isOpen;
}

function handleCountryChange() {
	selectedTimezone.value = '';
}

async function updateCountryAndTimezone() {
	if (profile.value?.id) {
		const payload: CountryTimezoneRequest = {
			timezoneId: selectedTimezone.value,
			countryOfResidenceId: selectedCountry.value,
		};
		await profileStore.updateProfileCountryTimezone(profile.value?.id, payload);
	}
}

async function handleBeforeSave() {
	// Stay in website if user select default country(US)
	if (selectedCountry.value === DEFAULT_COUNTRY) {
		await updateCountryAndTimezone();
		if (profileStore.errorMessage) {
			notificationStore.showErrorNotification(profileStore.errorMessage);
			return;
		}

		// Fetch profile data after update
		await profileStore.myAccountInfo();
		notificationStore.showSuccessNotification('Successfully updated your information.');
	} else {
		// Navigate old peatix
		isShowConfirmCountryDialog.value = true;
	}
}

async function handleSwitchUI() {
	await updateCountryAndTimezone();
	if (profileStore.errorMessage) {
		isShowConfirmCountryDialog.value = false;
		notificationStore.showErrorNotification(profileStore.errorMessage);
		return;
	}
	if (profileStore.profileId) {
		// Navigate to old peatix without sign out
		authStore.switchToPeatix();
	}
}

function handleDiscardSwitchUI() {
	if (profile.value) {
		selectedCountry.value = profile.value.countryId;
		selectedTimezone.value = profile.value.timezone;
	}
	isShowConfirmCountryDialog.value = false;
	isAgreeWithTerms.value = false;
}

async function handleDisconnectSnsAccount(sns: string) {
	if (!profile.value) {
		return;
	}

	if (sns === AUTH_SERVICE.GOOGLE && authStore.googleId) {
		await authStore.deleteSnsConnect(profile.value.id, authStore.googleId);
	} else if (sns === AUTH_SERVICE.APPLE && authStore.appleId) {
		await authStore.deleteSnsConnect(profile.value.id, authStore.appleId);
	}

	if (authStore.errorMessage) {
		notificationStore.showErrorNotification(authStore.errorMessage);
	} else {
		notificationStore.showSuccessNotification(`Successfully disconnected from your ${sns} account.`);
	}

	handleCheckSnsConnect(profile.value.id);
}

onMounted(() => {
	if (profile.value) {
		if (countryOptions.value && countryOptions.value.length) {
			selectedCountry.value = profile.value.countryId;
			selectedTimezone.value = profile.value.timezone;
		}

		currentUserEmail.value = profile.value?.email;
		handleCheckSnsConnect(profile.value.id);
	}
});

watch(timezones, (newTimezones) => {
	if (!selectedTimezone.value && newTimezones.length) {
		selectedTimezone.value = newTimezones[0].timezone;
	}
});

watch([profile, countryOptions], ([newProfile, newCountryOptions]) => {
	if (newProfile) {
		if (newCountryOptions && newCountryOptions.length) {
			selectedCountry.value = newProfile.countryId;
			selectedTimezone.value = newProfile.timezone;
		}

		currentUserEmail.value = newProfile.email;
	}
});

watch(() => profile.value?.id, async (newId) => {
	if (newId) {
		await handleCheckSnsConnect(newId);
	}
});

watch(isShowChangeEmailDialog, (isShow) => {
	if (isShow) {
		v$.value.$reset();
		newUserEmail.value = '';
	}
});

watch(newUserEmail, () => {
	v$.value.$clearExternalResults();
	$externalResults.newUserEmail = [];
});

watch(() => profileStore.errorUpdateEmailMessage, (serverErrorMessage) => {
	$externalResults.newUserEmail = [serverErrorMessage];
});
</script>
<template>
  <div>
    <div class="form-setting">
      <div class="row">
        <label class="text-sm text-semibold">Connected accounts</label>
        <div :class="['content', { 'fit-content': !isLoading && !isMobile }]">
          <template v-if="isFetching">
            <ButtonSkeletonLoader
              width="300"
              height="46"
            />
            <ButtonSkeletonLoader
              width="300"
              height="46"
            />
          </template>
          <template v-else>
            <template v-if="authStore.isGoogleConnected">
              <div class="disconnect-btn text-md text-semibold">
                <GoogleIcon
                  width="20"
                  height="20"
                />
                Connected Google account
                <BaseButton
                  class="close"
                  variant="subtle"
                  color="gray"
                  size="sm"
                  icon-only
                  :disabled="isLoading"
                  @click="handleDisconnectSnsAccount(AUTH_SERVICE.GOOGLE)"
                >
                  <CloseCircleIcon />
                </BaseButton>
              </div>
            </template>
            <template v-else-if="!authStore.isGoogleConnected">
              <BaseButton
                class="sns-btn"
                variant="outlined"
                color="gray"
                size="lg"
                :disabled="isLoading"
                @click="handleGoogleLoginClick"
              >
                <template #leftIcon>
                  <GoogleIcon
                    width="20"
                    height="20"
                  />
                </template>
                Connect Google account
              </BaseButton>
            </template>
            <template v-if="authStore.isAppleConnected">
              <div class="disconnect-btn text-md text-semibold">
                <AppleCircleIcon
                  width="20"
                  height="20"
                />
                Connected Apple account
                <BaseButton
                  class="close"
                  variant="subtle"
                  color="gray"
                  size="sm"
                  icon-only
                  :disabled="isLoading"
                  @click="handleDisconnectSnsAccount(AUTH_SERVICE.APPLE)"
                >
                  <CloseCircleIcon />
                </BaseButton>
              </div>
            </template>
            <template v-else>
              <BaseButton
                class="sns-btn"
                variant="outlined"
                color="gray"
                size="lg"
                :disabled="isLoading"
                @click="handleAppleLoginClick"
              >
                <template #leftIcon>
                  <AppleCircleIcon
                    width="20"
                    height="20"
                  />
                </template>
                Connect Apple account
              </BaseButton>
            </template>
          </template>
          <div
            id="appleid-signin"
            class="display-none"
          />
        </div>
      </div>
      <Divider />
      <div class="row">
        <label class="text-sm text-semibold">Email</label>
        <div class="content">
          <TextInputSkeletonLoader
            :loading="isFetching"
            height="46"
          >
            <BaseTextInput
              v-model="currentUserEmail"
              class="email-input"
              disabled
              type="email"
            />
          </TextInputSkeletonLoader>
          <div class="change-email-wrapper">
            <template v-if="!isFetching">
              <template v-if="isEmailVerified">
                <BaseChip
                  color="success"
                  size="default"
                >
                  <CheckVerifiedIcon />
                  <span>Verified</span>
                </BaseChip>
                <BaseButton
                  class="no-padding"
                  :variant="BtnVariant[3]"
                  :size="BtnSize[1]"
                  :disabled="isLoading"
                  @click="isShowConfirmChangeEmailDialog = true"
                >
                  Change email
                </BaseButton>
              </template>
              <template v-else>
                <BaseChip
                  color="warning"
                  size="default"
                >
                  <span>Not Verified</span>
                </BaseChip>
                <BaseButton
                  class="no-padding"
                  :variant="BtnVariant[3]"
                  :size="BtnSize[1]"
                  :disabled="isLoading"
                  @click="handleVerifyAccount"
                >
                  Verify account
                </BaseButton>
              </template>
            </template>
            <template v-else>
              <VSkeletonLoader
                min-width="100px"
                type="list-item"
              />
              <VSkeletonLoader
                min-width="105px"
                type="list-item"
              />
            </template>
          </div>
        </div>
      </div>
      <Divider />
      <div class="row">
        <label class="text-sm text-semibold">Country</label>
        <TextInputSkeletonLoader
          :loading="isFetching"
          height="46"
        >
          <BaseDropdown
            v-model="selectedCountry"
            item-name="name"
            item-value="id"
            :items="countryOptions"
            :disabled="isLoading"
            @update:model-value="handleCountryChange"
          />
        </TextInputSkeletonLoader>
      </div>
      <div
        v-if="timezones.length > 1"
        class="row"
      >
        <label class="text-sm text-semibold">Time zone</label>
        <TextInputSkeletonLoader
          :loading="isFetching"
          height="46"
        >
          <BaseTimezonePicker
            class="w-full"
            :selected-timezone="selectedTimezone"
            :timezone-options="timezones"
            @on-select-timezone="handleSelectTimezone"
            @on-menu-change="handleTimezoneDropdownMenuChange"
          >
            <template #dropdownActivator>
              <BaseTextInput
                v-model="displaySelectedTimezoneText"
                :disabled="isLoading"
                readonly
              >
                <template #rightIcon>
                  <component
                    :is="chevronIconComponent"
                    class="icon-chevron"
                    color="#667085"
                    width="20"
                    height="20"
                  />
                </template>
              </BaseTextInput>
            </template>
          </BaseTimezonePicker>
        </TextInputSkeletonLoader>
      </div>
      <div class="footer">
        <ButtonSkeletonLoader
          :loading="isFetching"
          width="65"
          height="42"
        >
          <BaseButton
            :size="BtnSize[1]"
            :disabled="isLoading"
            @click="handleBeforeSave"
          >
            Save
          </BaseButton>
        </ButtonSkeletonLoader>
      </div>
    </div>
    <!-- Verify Account Dialog -->
    <VerifyAccountDialog
      ref="verifyEmailDlg"
      :type="AUTH_FORM.ACCOUNT_VERIFY"
      :auth-code-type="authCodeType"
      :email="currentUserEmail"
      :confirm-button-name="confirmButtonName"
    />
    <!-- Verify New Account Dialog -->
    <VerifyNewAccountDialog
      ref="verifyNewEmailDlg"
      :current-email="currentUserEmail"
      :new-email="newUserEmail"
      @on-resend-code="sendCodeToNewEmail"
    />
    <!-- Confirm change email dialog -->
    <ConfirmDialog
      :is-show="isShowConfirmChangeEmailDialog"
      confirm-button-name="Proceed"
      @on-close="isShowConfirmChangeEmailDialog = false"
      @on-confirm="handleVerifyCurrentEmail"
    >
      <template #header>
        <MailIcon
          class="icon-header-confirm-dlg icon-info"
          :color="ICON_COLOR.PRIMARY"
        />
      </template>
      <template #title>
        Change email
      </template>
      <template #content>
        <p class="text-sm text-regular">
          To verify your identity, we will send a verification code to <span class="text-semibold">{{ currentUserEmail
          }}</span>.
        </p>
      </template>
    </ConfirmDialog>

    <!-- Update email dialog -->
    <ConfirmDialog
      confirm-button-name="Proceed"
      cancel-button-name="Go back"
      :is-show="isShowChangeEmailDialog"
      :is-loading="isLoading"
      @on-close="handleCloseChangeEmailDialog"
      @on-confirm="handleUpdateEmail"
    >
      <template #header>
        <MailIcon
          class="icon-header-confirm-dlg icon-info"
          :color="ICON_COLOR.PRIMARY"
        />
      </template>
      <template #title>
        Change email
      </template>
      <template #content>
        <div class="confirmation-dlg-content">
          <p class="text-sm text-regular">
            To verify your identity, we will send a verification code to the following new email address.
          </p>
          <BaseTextInput
            v-model="newUserEmail"
            label="New email address"
            :error-message="getValidationErrorMessage(v$.newUserEmail.$errors)"
          />
        </div>
      </template>
    </ConfirmDialog>

    <!-- Confirm change country and timezone -->
    <ConfirmDialog
      class="confirm-country-dlg"
      confirm-button-name="Proceed"
      hide-close-icon
      persistent
      :size="DIALOG_SIZE.MEDIUM"
      :is-show="isShowConfirmCountryDialog"
      :is-loading="isLoading"
      :disabled-confirm="!isAgreeWithTerms"
      @on-close="handleDiscardSwitchUI"
      @on-confirm="handleSwitchUI"
    >
      <template #content>
        <div class="content">
          <h1 class="title text-semibold">
            Experience a localized interface outside the US
          </h1>
          <p class="text-sm text-regular">
            We’ll be switching your interface to give you the most user friendly experience based on your country
            setting.
          </p>
          <img
            class="preview-image"
            :src="switchSitePreview"
            alt="switch website preview"
          >
          <BaseCheckbox
            id="tos"
            v-model="isAgreeWithTerms"
            name="ticket"
            label="Free"
            value="Free"
            :disabled="isLoading"
          >
            <template #labelText>
              <p class="text-sm text-regular">
                <span>By checking this box, you are agreeing to the </span>
                <a
                  href="http://about.peatix.com/tos.html?hl=en&force=jp"
                  target="_blank"
                >Terms of Service</a>
                <span> and </span>
                <a
                  href="http://about.peatix.com/privacy.html?hl=en&force=jp"
                  target="_blank"
                >Privacy Policy</a>
                <span> applicable to Peatix services offered in non-U.S. regions.</span>
              </p>
            </template>
          </BaseCheckbox>
        </div>
      </template>
      <template #tooltip>
        <p v-if="!isAgreeWithTerms">
          Please accept the terms to proceed.
        </p>
      </template>
    </ConfirmDialog>
    <AppleLoginFailByPopupBlockDialog
      :is-show="isShowAppleLoginFailedDialog"
      @on-close="isShowAppleLoginFailedDialog = false"
      @on-confirm="isShowAppleLoginFailedDialog = false"
    />
  </div>
</template>
<style scoped lang="scss">
.form-setting {
  .email-input {
    width: 100%;
  }

  .change-email-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sns-btn {
    display: flex;
    justify-content: flex-start;
    width: rem(300);

    @include media-query-max(mobile) {
      width: 100%;
    }
  }

  :deep(.v-skeleton-loader > .v-skeleton-loader__bone) {
    margin: 0 0 spacings-get(4) 0;
  }

  .disconnect-btn {
    display: grid;
    grid-template-columns: rem(20) 1fr rem(38);
    align-items: center;
    column-gap: spacings-get(2);
    color: colors-get(gray, 600);
  }
}

.confirmation-dlg-content {
  display: grid;
  gap: spacings-get(4);
}

.icon-chevron {
  width: auto;
  padding-right: rem(14);
  box-sizing: border-box;
}

.confirm-country-dlg {
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: spacings-get(4);
    text-align: center;

    .title {
      width: 80%;
      margin: spacings-get(4) auto 0;
      @include fonts-size('display-xs');

      @include media-query-max(mobile) {
        margin-top: spacings-get(2);
        @include fonts-size('text-xl');
      }
    }

    .preview-image {
      margin-top: spacings-get(4);
      min-height: 200px;
    }
  }
}
</style>