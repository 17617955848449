import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@vueuse/head';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-timepicker/dist/VueTimepicker.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import InfiniteLoading from 'v3-infinite-loading';
import 'v3-infinite-loading/lib/style.css'; // required if you're not going to override default slots
import QrcodeVue from 'qrcode.vue';
import Vue3Lottie from 'vue3-lottie';
import * as Sentry from '@sentry/vue';
import { createGtm } from '@gtm-support/vue-gtm';
import 'core-js/actual/array/at'; // Fix issue with node versions prior to 16.6 not supporting Array.at()

// @ts-ignore
import VueTimepicker from 'vue3-timepicker';
import router from './router';
import App from './App.vue';
import vuetify from './plugins/vuetify';

const app = createApp(App);

if (import.meta.env.VITE_ENV !== ENVIRONMENT.DEVELOPMENT) {
	Sentry.init({
		app,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_ENV,
		integrations: [],
	});
}

app.use(vuetify);
app.use(createPinia());
app.use(router);
app.use(createGtm({
	id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
	// Only add params on staging environment (restoretest, dev instance, local)
	...(import.meta.env.VITE_ENV !== ENVIRONMENT.PRODUCTION && {
		queryParams: {
			gtm_auth: 'iuA513fwFV5h7d9vKdma9w', // required when using custom environments
			gtm_preview: 'env-33',
			gtm_cookies_win: 'x',
		},
	}),
}));
app.use(createHead());

app.component('VueDatePicker', VueDatePicker);
app.component('VueTimepicker', VueTimepicker);
app.component('InfiniteLoading', InfiniteLoading);
app.component('QrcodeVue', QrcodeVue);
app.component('Vue3Lottie', Vue3Lottie);

app.mount('#app');
