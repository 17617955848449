<script setup lang="ts">
import type { AuthConnectSnsAccountRequest, AuthForm, AuthenticationType } from '@/types/auth';
import type {
	GTM_AUTH_METHOD_PARAMS as GTM_AUTH_METHOD_PARAMS_ENUM,
	GTM_EVENT_NAME as GTM_EVENT_NAME_ENUM,
} from '@/enums/gtm';

enum AUTH_PAGE {
	LOGIN,
	VERIFICATION,
	CONNECT_WITH_SNS,
}

const props = defineProps({
	type: {
		type: Number as PropType<AuthenticationType>,
		default: AUTH_FORM.SIGN_IN,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
	isSidebar: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onSwitchType'): void
	(e: 'onStepChange', step: AUTH_PAGE): void
}>();

const authStore = useAuthStore();
const notificationStore = useNotificationStore();
const { trackEvent } = useGtm();

const userEmail = ref<string>('');
const step = ref<AUTH_PAGE>(AUTH_PAGE.LOGIN);
const connectWithSnsType = ref();
const isShowAppleLoginFailedDialog = ref(false);

const backBtnText = computed(() => {
	switch (props.type) {
		case AUTH_FORM.SIGN_IN:
			return 'Back to sign in different ways';
		case AUTH_FORM.SIGN_UP:
			return 'Back to register in different ways';
		default:
			return '';
	}
});

const buttonName = computed(() => {
	return props.type === AUTH_FORM.SIGN_UP ? 'Sign in' : 'Register';
});

const handleGTMAuthEvent = async (
	eventType: string,
	methodType: GTM_AUTH_METHOD_PARAMS_ENUM,
	failureEvent: GTM_EVENT_NAME_ENUM,
) => {
	if (authStore.errorMessage && !authStore.snsConnectMetaData) {
		trackEvent({
			[eventType]: methodType,
			event: failureEvent,
		});
	}
};

async function handleGoogleLogin(authCode: string) {
	await authStore.authWithGoogle({
		service: AUTH_SERVICE.GOOGLE,
		secret: authCode,
	});

	if (authStore.snsConnectMetaData) {
		connectWithSnsType.value = AUTH_SERVICE.GOOGLE;
		step.value = AUTH_PAGE.CONNECT_WITH_SNS;
		emit('onStepChange', step.value);
		userEmail.value = authStore.snsConnectMetaData.email;
	}

	if (props.type === AUTH_FORM.SIGN_IN) {
		await handleGTMAuthEvent(
			'sign_in_method',
			GTM_AUTH_METHOD_PARAMS.GOOGLE,
			GTM_EVENT_NAME.USER_SIGN_IN_FAILURE,
		);
	} else if (props.type === AUTH_FORM.SIGN_UP) {
		await handleGTMAuthEvent(
			'register_method',
			GTM_AUTH_METHOD_PARAMS.GOOGLE,
			GTM_EVENT_NAME.USER_REGISTER_FAILURE,
		);
	}
}

async function handleAppleLogin(data: AppleSignInAPI.SignInResponseI) {
	await authStore.authWithApple({
		service: AUTH_SERVICE.APPLE,
		secret: data.authorization.code,
		token: data.authorization.id_token,
		client_id: import.meta.env.VITE_APPLE_CLIENT_ID,
		redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
		user: getDefaultUser(data),
	});

	if (authStore.snsConnectMetaData) {
		connectWithSnsType.value = AUTH_SERVICE.APPLE;
		step.value = AUTH_PAGE.CONNECT_WITH_SNS;
		emit('onStepChange', step.value);
		userEmail.value = authStore.snsConnectMetaData.email;
	}

	if (props.type === AUTH_FORM.SIGN_IN) {
		await handleGTMAuthEvent(
			'sign_in_method',
			GTM_AUTH_METHOD_PARAMS.APPLE,
			GTM_EVENT_NAME.USER_SIGN_IN_FAILURE,
		);
	} else if (props.type === AUTH_FORM.SIGN_UP) {
		await handleGTMAuthEvent(
			'register_method',
			GTM_AUTH_METHOD_PARAMS.APPLE,
			GTM_EVENT_NAME.USER_REGISTER_FAILURE,
		);
	}
}

function handleSendCode() {
	authStore.sendVerificationCode(userEmail.value);
}

async function handleAuthWithCode(code: string) {
	if (connectWithSnsType.value) {
		const snsConnectPayload: AuthConnectSnsAccountRequest = {
			service: connectWithSnsType.value,
			secret: authStore.snsConnectMetaData?.snsSecret || '',
			...(connectWithSnsType.value === AUTH_SERVICE.APPLE && {
				token: authStore.snsConnectMetaData?.snsToken,
				client_id: import.meta.env.VITE_APPLE_CLIENT_ID,
				redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
				user: authStore.snsConnectMetaData?.user,
			}),
		};
		await authStore.authWithCode(code, userEmail.value, snsConnectPayload);
	} else {
		await authStore.authWithCode(code, userEmail.value);
	}

	if (props.type === AUTH_FORM.SIGN_IN) {
		await handleGTMAuthEvent(
			'sign_in_method',
			GTM_AUTH_METHOD_PARAMS.MAGIC_CODE,
			GTM_EVENT_NAME.USER_SIGN_IN_FAILURE,
		);
	} else if (props.type === AUTH_FORM.SIGN_UP) {
		await handleGTMAuthEvent(
			'register_method',
			GTM_AUTH_METHOD_PARAMS.MAGIC_CODE,
			GTM_EVENT_NAME.USER_REGISTER_FAILURE,
		);
	}
}

async function handleEmailLogin(form: AuthForm) {
	userEmail.value = form.email;
	switch (props.type) {
		case AUTH_FORM.SIGN_IN:
			await authStore.validateEmail(form.email);

			if (authStore.errorMessage) {
				trackEvent({
					sign_in_method: GTM_AUTH_METHOD_PARAMS.MAGIC_CODE,
					event: GTM_EVENT_NAME.USER_SIGN_IN_FAILURE,
				});
			}
			break;
		case AUTH_FORM.SIGN_UP:
			await authStore.registerNewUserWithEmail(form);

			if (authStore.errorMessage) {
				trackEvent({
					register_method: GTM_AUTH_METHOD_PARAMS.MAGIC_CODE,
					event: GTM_EVENT_NAME.USER_REGISTER_FAILURE,
				});
			}
			break;
		default:
			break;
	}
}

function handleConnectWithSNS() {
	step.value = AUTH_PAGE.VERIFICATION;
	emit('onStepChange', step.value);
	handleSendCode();
}

function handleCancelConnectWithSNS() {
	step.value = AUTH_PAGE.LOGIN;
	emit('onStepChange', step.value);
	connectWithSnsType.value = '';
	userEmail.value = '';
}

function handleBackFromVerifyMagicCode() {
	step.value = AUTH_PAGE.LOGIN;
	emit('onStepChange', step.value);
}

onMounted(() => {
	step.value = AUTH_PAGE.LOGIN;
	emit('onStepChange', step.value);
});

watch(
	() => props.type,
	() => {
		step.value = AUTH_PAGE.LOGIN;
		emit('onStepChange', step.value);
	},
);

watch(
	() => authStore.isSendVerifyCode,
	(newIsSendVerifyCode) => {
		if (newIsSendVerifyCode) {
			step.value = AUTH_PAGE.VERIFICATION;
			emit('onStepChange', step.value);
		}
	},
);

watch(
	() => authStore.isEmailValid,
	(isEmailValid) => {
		if (!isEmailValid) {
			return;
		}
		// TODO: Check if email exist for sign up
		handleSendCode();
	},
);

watch(
	() => authStore.snsConnectErrorMessage,
	(snsConnectErrorMessage) => {
		if (!snsConnectErrorMessage) {
			return;
		}
		notificationStore.showErrorNotification(snsConnectErrorMessage);
	},
);
</script>
<template>
  <div class="authentication-form auth-page-container">
    <AuthenticationForm
      v-if="step === AUTH_PAGE.LOGIN"
      :type="type"
      :is-loading="isLoading"
      :sns-server-error-message="authStore.snsErrorMessage"
      :server-error-message="authStore.errorMessage"
      :is-sidebar="isSidebar"
      @on-google-login-success="handleGoogleLogin"
      @on-apple-login-success="handleAppleLogin"
      @on-apple-login-failed-by-popup-block="isShowAppleLoginFailedDialog = true"
      @on-submit-email="handleEmailLogin"
    >
      <template #footer>
        <BaseButton
          v-if="isSidebar && step === AUTH_PAGE.LOGIN"
          variant="link"
          color="primary"
          size="lg"
          :disabled="isLoading"
          @click="$emit('onSwitchType')"
        >
          {{ buttonName }}
        </BaseButton>
      </template>
    </AuthenticationForm>
    <CodeVerificationForm
      v-else-if="step === AUTH_PAGE.VERIFICATION"
      class="code-verification"
      :is-loading="isLoading"
      :server-error="authStore.errorMessage"
      @on-submit-code="handleAuthWithCode"
      @on-resend-code="handleSendCode"
      @on-cancel="handleBackFromVerifyMagicCode"
    >
      <template #header>
        <h5
          v-if="!isSidebar"
          class="title text-display-sm text-semibold"
        >
          Enter Code
        </h5>
      </template>
      <template #content>
        <AuthenticationContent
          :type="type"
          :email="userEmail"
        />
      </template>
      <template #footer>
        <Divider />
        <BaseButton
          variant="subtle"
          color="primary"
          class="w-full"
          @click="handleBackFromVerifyMagicCode"
        >
          <template #leftIcon>
            <ArrowCircleLeftIcon />
          </template>
          {{ backBtnText }}
        </BaseButton>
      </template>
    </CodeVerificationForm>
    <ConnectWithSNSForm
      v-else-if="step === AUTH_PAGE.CONNECT_WITH_SNS"
      :type="connectWithSnsType"
      @on-confirm="handleConnectWithSNS"
      @on-cancel="handleCancelConnectWithSNS"
    />
    <AppleLoginFailByPopupBlockDialog
      :is-show="isShowAppleLoginFailedDialog"
      @on-close="isShowAppleLoginFailedDialog = false"
      @on-confirm="isShowAppleLoginFailedDialog = false"
    />
  </div>
</template>
<style scoped lang="scss">
.authentication-form {
	display: flex;
	flex-direction: column;
	align-items: unset;
	gap: spacings-get(4);
	padding: 0;

	:deep(.auth-page-container) {
		padding: 0;
		margin: 0;
	}

	.code-verification {
		gap: spacings-get(8);

		:deep(.form-container) {
			gap: spacings-get(8);
		}

		.title {
			margin-bottom: spacings-get(6);
		}
	}
}
</style>