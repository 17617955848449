<script setup lang="ts">
import type { ButtonVariant } from '@/types/button';


const props = defineProps({
	url: {
		type: String,
		default: '',
		required: true,
	},
	userId: {
		type: Number as PropType<number | null>,
		default: null,
		required: true,
	},
	groupId: {
		type: Number as PropType<number | null>,
		default: null,
	},
	eventId: {
		type: Number as PropType<number | null>,
		default: null,
	},
	variant: {
		type: String as PropType<ButtonVariant>,
		default: BtnVariant[1],
		required: true,
	},
	showOnlyIcon: {
		type: Boolean,
		required: false,
	},
	gtmEventName: {
		type: String,
		default: '',
		required: true,
	},
});

const notificationStore = useNotificationStore();
const { trackEvent } = useGtm();

const isShowText = computed(() => !props.showOnlyIcon);

function handleCopyShareLink() {
	if (!props.url) {
		return;
	}

	copyToClipboard(props.url)
		.then(() => {
			notificationStore.showSuccessNotification('Successfully copied URL to clipboard.');

			trackEvent({
				event: props.gtmEventName,
				user_id: props.userId,
				...(props.groupId ? { group_id: props.groupId } : {}),
				...(props.eventId ? { event_id: props.eventId } : {}),
			});
		})
		.catch(() => {
			notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.TRY_AGAIN);
		});
}
</script>

<template>
  <BaseButton
    :variant="variant"
    color="gray"
    size="sm"
    @click="handleCopyShareLink"
  >
    <ShareIcon />
    <span v-if="isShowText">Share</span>
    <template #tooltip>
      <p v-if="!isShowText">
        Share
      </p>
    </template>
  </BaseButton>
</template>