import type { UtcTimezoneInfo } from '@/types/common';
import type { EventDetailResponse, EventDetail, EventTicket } from '@/types/event';
import dayjs from 'dayjs';

export const derivedEventSoldoutStatus = (status: string, end: UtcTimezoneInfo) => {
	// Derived final status on sold_out event
	if (status !== EVENT_STATUS.SOLD_OUT) {
		return status;
	}
	const isPublish = dayjs().isBefore(end.utc);
	const isSoldOutBeforeFinished = status === EVENT_STATUS.SOLD_OUT && isPublish;

	if (isSoldOutBeforeFinished) {
		return EVENT_STATUS.OPEN;
	}
	return EVENT_STATUS.FINISHED;
};

export const transformEventDetail = (data: EventDetailResponse): EventDetail => {
	const coordinateArray: String[] | null = data.locationSettings.venueGeoCoordinates ? data.locationSettings.venueGeoCoordinates.split(',') : null;
	const coordinate = coordinateArray ? {
		lat: Number(coordinateArray[0]),
		lng: Number(coordinateArray[1]),
	} : null;

	const tickets = data.tickets
		.filter((ticket) => ticket.status !== EVENT_STATUS.DELETED)
		.map((ticket): EventTicket => {
			return {
				id: ticket.id ?? null,
				name: ticket.name,
				quantity: ticket.seatsMax,
				soldQuantity: ticket.seatsSold ?? null,
				currency: ticket.currency ?? null,
				price: ticket.price,
				status: ticket.status,
				promocodes: ticket.promocodes ?? [],
				requiresTokenGates: ticket.requiresTokenGates,
			};
		});

	const status = derivedEventSoldoutStatus(data.status, data.end);
	const isSoldout = data.status === EVENT_STATUS.SOLD_OUT;

	return {
		id: data.id,
		status,
		title: data.name,
		description: data.details.description,
		startDateTime: data.start.utc,
		endDateTime: data.end.utc,
		timezone: data.start.timezone,
		publishedDateTime: data.published?.utc,
		publishedTimezone: data.published?.timezone,
		locationType: data.locationType,
		location: {
			name: data.locationSettings.venueName,
			address: data.locationSettings.venueAddress,
			coordinate,
		},
		url: data.streamingSettings?.url ?? '',
		instruction: data.streamingSettings?.attendeeInfo ?? '',
		coverUrl: data.details.coverUrl ?? '',
		tickets,
		organizerId: data.organizerInfo.userId,
		organizerGroupId: data.groupId,
		tokenGates: {
			contractAddress: data.tokenGates?.contract_address,
			chainId: data.tokenGates?.chain_id,
		},
		tokenGatingOptions: data.tokenGatingOptions,
		hasAiCoverImage: data.miscSettings.hasAiCoverImage,
		isSoldout,
		countryId: data.countryId,
	};
};
