import type { PaginationInfo } from '@/types/common';
import type { EventInfo } from '@/types/event';
import type { LocationCoordinate } from '@/types/location';
import type { EventSearchQueryRequest, EventSearchResponse } from '@/types/search';

interface SearchStore {
	events: {
		data: EventSearchResponse[]
		pagination: PaginationInfo | null
		isLoading: boolean
		errorMessage: string,
	},
	payload: {
		keyword: string;
		selectedLocation: {
			coordinates: LocationCoordinate | null;
			name: string;
			city: string;
			country: string;
			countryCode: string;
		}
		currentLocation: {
			coordinates: LocationCoordinate | null;
			name: string;
			city: string;
			country: string;
			countryCode: string;
		}
	},
	isFetching: boolean;
}

const useSearchStore = defineStore('search', {

	state: (): SearchStore => ({
		events: {
			data: [],
			pagination: null,
			isLoading: false,
			errorMessage: '',
		},
		payload: {
			keyword: '',
			selectedLocation: {
				coordinates: null,
				name: '',
				city: '',
				country: '',
				countryCode: '',
			},
			currentLocation: {
				coordinates: null,
				name: '',
				city: '',
				country: '',
				countryCode: '',
			},
		},
		isFetching: false,
	}),

	getters: {
		searchItems: (state: SearchStore): EventInfo[] => {
			if (!state.events.data.length) {
				return [];
			}
			return transformEventSearchResponse(state.events.data);
		},
	},

	actions: {
		async getEventsBySearch(query: EventSearchQueryRequest) {
			this.events.isLoading = true;

			const { data, errorMessage } = await getSearchEventsAPI(query);

			if (errorMessage.value) {
				this.events.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.events.data = data.value.data;
				this.events.pagination = data.value.paginationInfo;
			}
			this.events.isLoading = false;
		},

		resetKeyword() {
			this.payload.keyword = '';
		},

		resetSelectedLocation() {
			this.payload.selectedLocation = {
				coordinates: null,
				name: '',
				city: '',
				country: '',
				countryCode: '',
			};
		},

		resetCurrentLocation() {
			this.payload.currentLocation = {
				coordinates: null,
				name: '',
				city: '',
				country: '',
				countryCode: '',
			};
		},
	},
});

export default useSearchStore;
