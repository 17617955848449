<script setup lang="ts">
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import avatarPlaceholder from '@/assets/svg/user-circle.svg';
import type { Group } from '@/types/group';

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
	isCreateMode: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const groupStore = useGroupStore();
const notificationStore = useNotificationStore();

const emit = defineEmits<{
	(e: 'onClose'): void
	(e: 'onUpdate', value: Group): void
}>();

const readableMaximumSize = formatByteSize(MAXIMUM_FILE_SIZE);

const profileFileUpload = ref<HTMLElement | null>();
const coverFileUpload = ref<HTMLElement | null>();
const tempProfileImageUrl = ref('');
const tempCoverImageUrl = ref('');
const tempProfileImageFile = ref<File | null>(null);
const tempCoverImageFile = ref<File | null>(null);

const formData = reactive<Group>({
	groupName: '',
	groupImageUrl: '',
	groupImageFile: null,
	groupDescription: '',
	groupCoverImageUrl: '',
	groupCoverImageFile: null,
	isDeletedLogoImage: false,
	isDeletedCoverImage: false,
});

const isShowValue = computed(() => props.isShow);
const dialogTitle = computed(() => (props.isCreateMode ? 'Create group' : 'Edit group'));

const uploadCoverImageBtnName = computed(() => {
	return formData.groupCoverImageUrl ? 'Change cover image' : 'Add cover image';
});

const avatarImage = computed(() => {
	return formData?.groupImageUrl ? formData?.groupImageUrl : avatarPlaceholder;
});

const rules = computed(() => {
	if (!props.isShow) {
		return {
			groupName: {},
			socials: {},
		};
	}

	return {
		groupName: {
			required: helpers.withMessage('Please enter your group name.', required),
		},
	};
});

const v$ = useVuelidate(rules, formData);

function updateProfile() {
	if (groupStore.groupInfo) {
		formData.groupName = groupStore.groupInfo.name;
		formData.groupDescription = groupStore.groupInfo.description === ' ' ? '' : groupStore.groupInfo.description; // Replace space with empty string
		formData.groupImageUrl = groupStore.groupInfo.logo;
		formData.groupCoverImageUrl = groupStore.groupInfo.cover;
	}
}

async function handleSelectProfileFileUpload(e: Event) {
	const target = e.target as HTMLInputElement;
	if (target && target.files?.length) {
		tempProfileImageFile.value = target.files[0];
		if (tempProfileImageFile.value?.size > MAXIMUM_FILE_SIZE) {
			notificationStore.showErrorNotification(`The image format is not supported. JPEG, PNG or GIF are supported with a maximum file size of ${readableMaximumSize}.`);
			tempProfileImageFile.value = null;
			return;
		}

		// Create a temporary preview image URL before save the file on API call
		tempProfileImageUrl.value = URL.createObjectURL(tempProfileImageFile.value);
		formData.isDeletedLogoImage = false;
		formData.groupImageUrl = tempProfileImageUrl.value;

		// Transform image file
		const newFile = await processImageFile(tempProfileImageFile.value, PROFILE_IMAGE_MAX_WIDTH);
		formData.groupImageFile = newFile;

		// Reset file upload
		target.value = '';
	} else if (!target.files?.length) {
		formData.groupImageFile = null;
		tempProfileImageFile.value = null;
	}
}

async function handleSelectCoverFileUpload(e: Event) {
	const target = e.target as HTMLInputElement;

	if (target && target.files?.length) {
		tempCoverImageFile.value = target.files[0];

		if (tempCoverImageFile.value?.size > MAXIMUM_FILE_SIZE) {
			notificationStore.showErrorNotification(`The image format is not supported. JPEG, PNG or GIF are supported with a maximum file size of ${readableMaximumSize}.`);
			tempCoverImageFile.value = null;
			return;
		}

		// Create a temporary preview image URL before save the file on API call
		tempCoverImageUrl.value = URL.createObjectURL(tempCoverImageFile.value);
		formData.isDeletedCoverImage = false;
		formData.groupCoverImageUrl = tempCoverImageUrl.value;

		// Transform image file
		const newFile = await processImageFile(tempCoverImageFile.value, PROFILE_COVER_IMAGE_MAX_WIDTH);
		formData.groupCoverImageFile = newFile;

		// Reset file upload
		target.value = '';
	} else if (!target.files?.length) {
		formData.groupCoverImageFile = null;
		tempCoverImageFile.value = null;
	}
}

function handleDeleteLogoImage() {
	// Reset flag to prevent trigger auto save when get new data
	const input = coverFileUpload.value as HTMLInputElement;
	input.value = '';

	formData.groupImageUrl = '';
	formData.groupImageFile = null;
	formData.isDeletedLogoImage = true;
}

function handleDeleteCoverImage() {
	// Reset flag to prevent trigger auto save when get new data
	const input = coverFileUpload.value as HTMLInputElement;
	input.value = '';

	formData.groupCoverImageUrl = '';
	formData.groupCoverImageFile = null;
	formData.isDeletedCoverImage = true;
}

function handleSubmit() {
	v$.value.$touch();

	if (v$.value.$error) {
		return;
	}
	emit('onUpdate', formData);
}

function handleClose() {
	if (!props.isCreateMode) {
		updateProfile();
	}
	v$.value.$reset();
	emit('onClose');
}

onMounted(() => {
	if (props.isCreateMode) {
		return;
	}
	updateProfile();
});

// Watch for changes in the group data and update formData
watch(() => groupStore.groupInfo, (newData) => {
	if (newData && !props.isCreateMode) {
		formData.groupName = newData.name;
		formData.groupImageUrl = newData.logo;
		formData.groupDescription = newData.description;
		formData.groupCoverImageUrl = newData.cover;
	}
});

// Reset state when show dialog
watch(() => props.isShow, (newIsShow) => {
	if (newIsShow) {
		if (!props.isCreateMode) {
			updateProfile();
		}
		v$.value.$reset();
	} else {
		formData.groupName = '';
		formData.groupImageUrl = '';
		formData.groupImageFile = null;
		formData.groupDescription = '';
		formData.groupCoverImageUrl = '';
		formData.groupCoverImageFile = null;
		formData.isDeletedLogoImage = false;
		formData.isDeletedCoverImage = false;
	}
});
</script>

<template>
  <BaseDialog
    :is-show="isShowValue"
    :size="DIALOG_SIZE.SMALL"
    @on-close="handleClose"
  >
    <template #header>
      <p class="text-lg text-semibold">
        {{ dialogTitle }}
      </p>
    </template>
    <template #content>
      <div class="group-editor-container">
        <!-- cover image -->
        <div class="group-cover-container text-md text-regular">
          <div :class="['cover-image', { 'place-holder': !formData.groupCoverImageUrl }]">
            <img
              v-if="formData.groupCoverImageUrl"
              :src="formData.groupCoverImageUrl"
            >
            <div class="action-btn-container">
              <BaseButton
                class="add-btn"
                size="sm"
                variant="outlined"
                color="gray"
                :disabled="disabled"
                @click="coverFileUpload?.click()"
              >
                <UploadIcon />
                {{ uploadCoverImageBtnName }}
              </BaseButton>
              <input
                ref="coverFileUpload"
                type="file"
                hidden
                accept="image/png, image/gif, image/jpeg"
                @change="handleSelectCoverFileUpload"
              >
              <BaseButton
                v-if="formData.groupCoverImageUrl"
                class="del-btn"
                variant="outlined"
                color="gray"
                :disabled="disabled"
                icon-only
                @click="handleDeleteCoverImage"
              >
                <template #leftIcon>
                  <TrashIcon />
                </template>
                <template #tooltip>
                  <p>Delete</p>
                </template>
              </BaseButton>
            </div>
          </div>
        </div>
        <!-- group image -->
        <div class="group-img-container">
          <div class="group-image">
            <AvatarImage
              class="img-wrapper"
              :image-url="avatarImage"
              :alt="formData?.groupName"
            />
            <div class="action-btn-container">
              <BaseButton
                class="add-btn"
                size="sm"
                variant="outlined"
                color="gray"
                :disabled="disabled"
                @click="profileFileUpload?.click()"
              >
                <template #leftIcon>
                  <UploadIcon />
                </template>
                <template #tooltip>
                  <p>Upload</p>
                </template>
              </BaseButton>
              <input
                ref="profileFileUpload"
                type="file"
                hidden
                accept="image/png, image/gif, image/jpeg"
                @change="handleSelectProfileFileUpload"
              >
              <BaseButton
                v-if="formData.groupImageUrl"
                class="del-btn"
                variant="outlined"
                color="gray"
                :disabled="disabled"
                icon-only
                @click="handleDeleteLogoImage"
              >
                <template #leftIcon>
                  <TrashIcon />
                </template>
                <template #tooltip>
                  <p>Delete</p>
                </template>
              </BaseButton>
            </div>
          </div>
        </div>
        <BaseTextInput
          v-model="formData.groupName"
          class="title-input"
          placeholder="Group name"
          :disabled="disabled"
          :error-message="getValidationErrorMessage(v$.groupName.$errors)"
        />
        <BaseTextArea
          v-model="formData.groupDescription"
          placeholder="Enter description"
          rows="5"
          font-size="md"
          :disabled="disabled"
        />
      </div>
    </template>
    <template #footer>
      <div class="btn-container">
        <BaseButton
          class="w-full"
          variant="outlined"
          color="gray"
          type="button"
          :disabled="disabled"
          @click="handleClose"
        >
          Cancel
        </BaseButton>
        <BaseButton
          class="w-full"
          variant="solid"
          color="primary"
          type="submit"
          :disabled="disabled"
          @click="handleSubmit"
        >
          Save
        </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.group-editor-container {
  display: flex;
  flex-direction: column;
  gap: spacings-get(5);
  padding: 0;

  @include media-query-max(mobile) {
    padding: 0 0 spacings-get(5) 0;
  }

  .group-cover-container {
    .cover-image {
      width: 100%;
      position: relative;
      display: flex;
      overflow: hidden;
      color: colors-get(gray, 500);
      background-color: colors-get(gray, 200);
      @include border-radius-default;
      aspect-ratio: 3 / 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .action-btn-container {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: spacings-get(3);
        display: flex;
        gap: spacings-get(2);

        .add-btn {
          padding: spacings-get(2) rem(14);
          gap: spacings-get(2);
        }

        .del-btn {
          padding: spacings-get(2);
        }
      }
    }
  }

  .group-img-container {
    position: relative;

    .group-image {
      position: relative;
      width: rem(96);
      height: rem(96);
      background-color: transparent;
      aspect-ratio: 1;

      .img-wrapper {
        width: 100%;
        height: 100%;
        @include border-radius-rounded;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }


      .action-btn-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .add-btn,
        .del-btn {
          width: rem(36);
          padding: spacings-get(2);
          @include border-radius-rounded;
          aspect-ratio: 1;
        }
      }
    }
  }
}

.btn-container {
  display: flex;
  gap: spacings-get(3);
  align-items: center;
  justify-content: space-between;
}
</style>