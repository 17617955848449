import type { GROUP_IMAGE_FIELD as GROUP_IMAGE_FIELD_TYPE } from '@/enums/group';
import type { ProfileMessageInfo } from '@/types/profile';
import type {
	GroupImageFile,
	GroupInfo,
	UpdatedGroupInfoResponse,
	PublicGroupInfo,
	GroupInList,
} from '@/types/group';
import type { FilterInfo, PaginationInfo } from '@/types/common';

interface GroupStore {
	group: {
		data: GroupInfo | null
		createdData: PublicGroupInfo | null
		updatedData: UpdatedGroupInfoResponse | null
	}
	groups: {
		data: GroupInList[]
		paginationInfo: PaginationInfo | null
	}
	errorMessage: string
	isLoading: boolean
}

const useGroupStore = defineStore('group', {
	state: (): GroupStore => ({
		group: {
			data: null,
			createdData: null,
			updatedData: null,
		},
		groups: {
			data: [],
			paginationInfo: null,
		},
		errorMessage: '',
		isLoading: false,
	}),

	getters: {
		groupId: (state: GroupStore): number | null => {
			if (!state.group.data) {
				return null;
			}
			return state.group.data.id;
		},

		groupInfo: (state: GroupStore) => {
			if (!state.group.data) {
				return null;
			}

			return transformProfileGroup(state.group.data, state.group.updatedData || null);
		},

		createdGroupId: (state: GroupStore): number | null => {
			if (!state.group.createdData) {
				return null;
			}
			return state.group.createdData.id;
		},
	},

	actions: {
		async createGroup(name: string, description?: string) {
			this.isLoading = true;
			this.group.createdData = null;
			this.errorMessage = '';

			// Create new peasy group if not found any peasy group
			const { data: userGroup, errorMessage: createGroupErrorMessage } = await postCreateUserGroup(
				name,
				description,
			);

			if (createGroupErrorMessage.value) {
				this.errorMessage = createGroupErrorMessage.value;
			}

			if (userGroup.value) {
				this.group.createdData = userGroup.value;
			}
			this.isLoading = false;
		},

		async getGroupInfo(groupId: number) {
			this.isLoading = true;
			this.group.data = null;
			this.errorMessage = '';

			const { data: groupInfo, errorMessage } = await getGroupInfoByGroupId(groupId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (groupInfo.value) {
				this.group.data = groupInfo.value;
			}
			this.isLoading = false;
		},

		async updateGroupInfo(groupId: number, groupInfo: Partial<GroupInfo>) {
			this.isLoading = true;
			this.group.updatedData = null;
			this.errorMessage = '';

			const { data: updatedData, errorMessage } = await patchUpdateGroupInfo(groupId, groupInfo);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (updatedData.value) {
				this.group.updatedData = updatedData.value;
			}
			this.isLoading = false;
		},

		async uploadGroupImage(
			groupId: number,
			file: GroupImageFile,
			fields: GROUP_IMAGE_FIELD_TYPE[],
		) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await postGroupCoverImageAPI(groupId, file, fields);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},

		async deleteGroupImage(groupId: number, field: GROUP_IMAGE_FIELD_TYPE) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await deleteGroupImageAPI(groupId, field);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},

		async getGroupInfoList(userId: number, filter: FilterInfo) {
			this.isLoading = true;
			this.errorMessage = '';
			this.groups.data = [];

			const { data: userGroups, errorMessage } = await getGroupListByUserIdAPI(userId, filter);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (userGroups.value && !!userGroups.value.data.length) {
				this.groups.data = userGroups.value.data;
				this.groups.paginationInfo = userGroups.value.paginationInfo;
			}
			this.isLoading = false;
		},

		async sendMessageToGroupFollowers(groupId: number, info: ProfileMessageInfo) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await postMessageToFollower(groupId, info);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			this.isLoading = false;
		},
	},
});

export default useGroupStore;
