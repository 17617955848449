<script setup lang="ts">
import type { ButtonColor } from '@/types/button';
import type { DialogSize } from '@/types/dialog';

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
	cancelButtonName: {
		type: String,
		default: 'Cancel',
	},
	confirmButtonName: {
		type: String,
		default: 'OK',
	},
	confirmButtonColor: {
		type: String as PropType<ButtonColor>,
		default: 'primary',
	},
	hideCancelButton: {
		type: Boolean,
		default: false,
	},
	hideCloseIcon: {
		type: Boolean,
		default: false,
	},
	size: {
		type: String as PropType<DialogSize>,
		default: DIALOG_SIZE.SMALL,
	},
	persistent: {
		type: Boolean,
		default: false,
	},
	disabledConfirm: {
		type: Boolean,
		default: false,
	},
});

defineEmits<{
	(e: 'onClose'): void
	(e: 'onConfirm'): void
}>();

const confirmBtnRef = ref();

const isShowValue = computed(() => props.isShow);
</script>

<template>
  <BaseDialog
    :is-show="isShowValue"
    :hide-close-icon="hideCloseIcon"
    :persistent="persistent"
    :size="size"
    :is-disabled-close-button="isLoading"
    @on-close="$emit('onClose')"
  >
    <template #header>
      <slot name="header" />
    </template>
    <template #content>
      <h1 class="dialog-confirm-title text-lg text-semibold">
        <slot name="title" />
      </h1>
      <div class="dialog-confirm-content">
        <slot name="content" />
      </div>
    </template>
    <template #footer>
      <div class="btn-container">
        <BaseButton
          v-if="!hideCancelButton"
          class="w-full  btn-cancel"
          variant="outlined"
          color="gray"
          type="button"
          :disabled="isLoading"
          @click="$emit('onClose')"
        >
          {{ cancelButtonName }}
        </BaseButton>
        <BaseButton
          ref="confirmBtnRef"
          class="w-full btn-confirm"
          variant="solid"
          :color="confirmButtonColor"
          :disabled="isLoading || disabledConfirm"
          @click="$emit('onConfirm')"
        >
          <BaseProgressCircular
            v-if="isLoading"
            class="dialog-spinner"
            indeterminate
          />
          <template v-else>
            {{ confirmButtonName }}
          </template>
        </BaseButton>
        <VTooltip
          v-if="$slots['tooltip']"
          :activator="confirmBtnRef"
          max-width="240"
          offset="0 0"
          location="bottom right"
        >
          <div class="tooltip">
            <slot name="tooltip" />
          </div>
        </VTooltip>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.dialog-confirm-title {
  color: colors-get(gray, 900);
}

.dialog-confirm-content {
  color: colors-get(gray, 600);
}

.btn-container {
  display: flex;
  gap: spacings-get(3);
  align-items: center;
  justify-content: space-between;

  @include media-query-max(mobile) {
    flex-direction: column-reverse;
  }

  .dialog-spinner {
    width: rem(24);
    height: rem(24);
  }
}
</style>
